import axiosInstance from '../services/axiosInstance';

// Fetch all artists for a shop (with token authorization)
export const getArtists = async (shopId, token = null) => {
  const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
  const response = await axiosInstance.get(`/shop/${shopId}/artists`, config);
  return response.data;
};

// Fetch an artist by ID
export const getArtistById = async (shopId, artistId) => {
  const response = await axiosInstance.get(`/shop/${shopId}/artists/${artistId}`);
  return response.data;
};

// Add a new artist to a shop
export const addArtist = async (shopId, token, newArtist) => {
  const formData = new FormData();
  formData.append('name', newArtist.name);
  formData.append('bio', newArtist.bio);
  formData.append('instagram', newArtist.instagram);
  if (newArtist.image) formData.append('image', newArtist.image);
  formData.append('services', JSON.stringify(newArtist.services || []));

  const response = await axiosInstance.post(`/shop/${shopId}/artists`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.artist;
};

// Update artist details
export const updateArtist = async (shopId, artistId, formData, token) => {
  const response = await axiosInstance.put(`/shop/${shopId}/artists/${artistId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.artist;
};

// Delete an artist
export const deleteArtist = async (shopId, artistId, token) => {
  await axiosInstance.delete(`/shop/${shopId}/artists/${artistId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Fetch services for an artist
export const fetchArtistServices = async (shopId, artistId, token) => {
  const response = await axiosInstance.get(`/shop/${shopId}/artists/${artistId}/services`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Update artist services
export const updateArtistServices = async (shopId, artistId, updatedServices, token) => {
  const response = await axiosInstance.put(
    `/shop/${shopId}/artists/${artistId}/services`,
    { services: updatedServices },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data.artist.services;
};

// Add a service to an artist
export const addService = async (shopId, artistId, service, token) => {
  const response = await axiosInstance.post(
    `/shop/${shopId}/artists/${artistId}/services`,
    { service },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data.artist.services;
};

// Remove a service from an artist
export const removeService = async (shopId, artistId, service, token) => {
  await axiosInstance.delete(`/shop/${shopId}/artists/${artistId}/services/${service}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Add a style to an artist
export const addStyle = async (shopId, artistId, style, token) => {
  const response = await axiosInstance.post(
    `/shop/${shopId}/artists/${artistId}/styles`,
    { style },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data.artist.styles;
};

// Remove a style from an artist
export const removeStyle = async (shopId, artistId, styleId, token) => {
  const response = await axiosInstance.delete(`/shop/${shopId}/artists/${artistId}/styles/${styleId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.artist.styles;
};

// Fetch all available styles
export const fetchStyles = async () => {
  const response = await axiosInstance.get('/styles');
  return response.data;
};

// Add a new artist to the shop
export const addArtistToShop = async (newArtist, shopId, token) => {
  const formData = new FormData();
  formData.append('name', newArtist.name);
  formData.append('bio', newArtist.bio);
  formData.append('instagram', newArtist.instagram);
  if (newArtist.image) formData.append('image', newArtist.image);
  formData.append('services', JSON.stringify(newArtist.services || []));

  const response = await axiosInstance.post(`/shop/${shopId}/artists`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.artist;
};

// Delete an artist from the shop
export const deleteArtistFromShop = async (artistId, shopId, token) => {
  await axiosInstance.delete(`/shop/${shopId}/artists/${artistId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Fetch unavailable dates for an artist
export const getUnavailableDatesForArtist = async (shopId, artistName, token) => {
  const response = await axiosInstance.get(`/shop/${shopId}/unavailable-dates/${artistName}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Add unavailable date
export const addUnavailableDate = async (shopId, newUnavailableDate, token) => {
  await axiosInstance.post(`/shop/${shopId}/unavailable-dates`, newUnavailableDate, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Delete unavailable date
export const deleteUnavailableDate = async (shopId, unavailableDateId, token) => {
  await axiosInstance.delete(`/shop/${shopId}/unavailable-dates/${unavailableDateId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
