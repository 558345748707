import React from 'react';

function ConfirmationModal({ isOpen, onConfirm, onCancel }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <h3 className="text-xl font-semibold mb-4 text-center">Confirm Deletion</h3>
        <p className="text-gray-700 text-center mb-6">
          Are you sure you want to delete this?
        </p>
        <div className="flex justify-center space-x-4">
          <button
            className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition"
            onClick={onConfirm}
          >
            Confirm
          </button>
          <button
            className="bg-gray-300 py-2 px-4 rounded-lg hover:bg-gray-400 transition"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
