import { getUnavailableDates, addUnavailableDate, deleteUnavailableDate } from '../models/UnavailableDatesModel';

export const fetchUnavailableDates = async (shopId, artistName, token, setUnavailableDates, setError) => {
  try {
    const data = await getUnavailableDates(shopId, artistName, token);
    setUnavailableDates(data);
  } catch (error) {
    setError('Failed to load unavailable dates.');
  }
};

export const handleAddUnavailableDate = async (
    shopId,
    token,
    unavailableDate,
    setNewUnavailableDate,
    setNewStartTime,
    setNewEndTime,
    setRecurrencePattern,
    setRecurrenceEndDate,
    fetchUnavailableDates,
    setError // Add setError here as a parameter
  ) => {
    try {
      await addUnavailableDate(shopId, unavailableDate, token);
      setNewUnavailableDate('');
      setNewStartTime('');
      setNewEndTime('');
      setRecurrencePattern('');
      setRecurrenceEndDate('');
      fetchUnavailableDates();
    } catch (error) {
      setError('Failed to add unavailable date.'); // Make sure setError is available here
    }
  };

export const handleDeleteUnavailableDate = async (
  shopId,
  dateId,
  token,
  fetchUnavailableDates,
  setError
) => {
  try {
    await deleteUnavailableDate(shopId, dateId, token);
    fetchUnavailableDates(); // Refresh after deletion
  } catch (error) {
    setError('Failed to delete unavailable date.');
  }
};
