// src/controllers/TattooStyleController.js
import { getTattooStyleById } from '../models/StyleModel';

// Fetch tattoo style details and update component state
export const fetchTattooStyleById = async (styleId, setStyle, setLoading, setErrorMessage) => {
  try {
    const data = await getTattooStyleById(styleId); // Fetch the data using model
    setStyle(data);
    setLoading(false);
  } catch (error) {
    setErrorMessage('Failed to load tattoo style details.');
    setLoading(false);
  }
};
