import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { ShopContext } from '../context/ShopContext';
import FeaturedArtwork from '../components/FeaturedArtwork';
import TattooStyleGuide from '../components/TattooStyleGuide';
import TattooProcess from '../components/TattooProcess';
import BookAppointment from '../components/BookAppointment';
import SatisfactionScore from '../components/SatisfactionScore';
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { initializeShopData, initializeFirstShop } from '../controllers/ShopHomeController'; // Import the controller logic

function ShopHomePage() {
  const [shopDetails, setShopDetails] = useState(null);
  const [artists, setArtists] = useState([]);
  const [reviews, setReviews] = useState([]);
  const { shopUsername, shopId, setShopId, setShopUsername } = useContext(ShopContext);
  const [carouselImages, setCarouselImages] = useState([]);
  const navigate = useNavigate();

  const testimonialSettings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Initialize AOS animations
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  // Fetch shop data when `shopUsername` is available
  useEffect(() => {
    if (shopUsername && shopId) {
      console.log('Fetching data for shopUsername:', shopUsername); // Debugging log
      initializeShopData(shopUsername, setShopId, setShopUsername, setShopDetails, setCarouselImages, setArtists, setReviews);
    } else {
      // WIP
      initializeFirstShop().then(() => {
        const newShopUsername = localStorage.getItem('shopUsername');
        const newShopId = localStorage.getItem('shopId');
        setShopUsername(newShopUsername);  // Use the setter function instead of directly assigning
        setShopId(newShopId);
        initializeShopData(newShopUsername, setShopId, setShopUsername, setShopDetails, setCarouselImages, setArtists, setReviews);
      }).catch(err => console.warn('Failed to initialize first shop:', err)); // Error handling for fetch
    }
  }, [shopUsername, shopId, setShopId, setShopUsername]);

  const handleBookAppointmentClick = () => {
    if (shopDetails) {
      navigate(`/${shopDetails.username}/book`);
    }
  };

  const formatBio = (bio) => {
    return bio.split('\n').map((text, index) => (
      <React.Fragment key={index}>
        {text}
        <br />
      </React.Fragment>
    ));
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    fade: true, // Added fade effect
  };

  if (!shopDetails) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-center">
        <h2 className="text-2xl font-bold mb-4">No Shop Found</h2>
        <Link to="/create-shop" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-transform transform hover:scale-105">
          Create Shop
        </Link>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto">
      {/* Shop Image Carousel */}
      {carouselImages.length > 0 && (
        <div className="relative w-screen left-1/2 right-1/2 -ml-[50vw] -mr-[50vw] mb-8">
          <Slider {...carouselSettings}>
            {carouselImages.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={`${image}`}
                  alt={`Shop Carousel ${index + 1}`}
                  className="w-screen h-96 object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
              </div>
            ))}
          </Slider>
        </div>
      )}

      <div className="px-4 py-10 animate__animated animate__fadeIn" data-aos="fade-up">
        <h1 className="text-5xl font-extrabold text-center mb-6 text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-purple-600">
          Welcome to {shopDetails.name}
        </h1>
        <p className="text-lg text-center text-gray-600 mb-10">{shopDetails.bio && formatBio(shopDetails.bio)}</p>

        {/* Artists Section */}
        <section className="mb-20" data-aos="fade-up">
          <h2 className="text-4xl font-semibold mb-12 text-center">Meet Our Artists</h2>
          {artists.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
              {artists.map((artist) => (
                <div key={artist._id} className="group relative bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition">
                  {artist.image && (
                    <div className="overflow-hidden rounded-full w-48 h-48 mx-auto mb-6">
                      <img
                        src={`${artist.image}`}
                        alt={artist.name}
                        className="w-full h-full object-cover transform group-hover:scale-110 transition duration-500"
                      />
                    </div>
                  )}
                  <h3 className="text-2xl font-bold text-center mb-2">{artist.name}</h3>
                  <p className="text-center text-gray-600 mb-4">{artist.bio.substring(0, 100)}...</p>
                  <Link to={`/${shopUsername}/artist/${artist._id}`} className="absolute inset-0">
                    <span className="sr-only">View {artist.name}'s profile</span>
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-600 text-center">No artists available at the moment.</p>
          )}
        </section>

        {/* Book appointment button */}
        <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="800">
          <BookAppointment handleBookAppointmentClick={handleBookAppointmentClick} />
        </div>

        {/* Reviews Section */}
        <section className="mb-20 bg-gray-100 py-16" data-aos="fade-up" data-aos-delay="200" data-aos-duration="800">
          <h2 className="text-4xl font-semibold mb-12 text-center">What Our Clients Say</h2>
          {reviews.length > 0 ? (
            <Slider {...testimonialSettings}>
              {reviews.map((review) => (
                <div key={review._id} className="px-8">
                  <div className="bg-white p-8 rounded-lg shadow-md">
                    <h3 className="text-xl font-bold mb-2">{review.title || 'Customer Review'}</h3>
                    <p className="text-yellow-500 mb-2">{'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}</p>
                    <p className="text-gray-700 mb-6">&ldquo;{review.comment}&rdquo;</p>
                    <div className="flex items-center">
                      <div>
                        <p className="font-bold">{review.reviewerName}</p>
                        <p className="text-sm text-gray-500">{review.artistName}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <p className="text-gray-600 text-center">No testimonials available at the moment.</p>
          )}
        </section>

        {/* Satisfaction Score */}
        <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="800">
          <SatisfactionScore shopId={shopId} />
        </div>

        {/* Featured Artwork */}
        <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="800">
          <FeaturedArtwork shopId={shopId} shopUsername={shopUsername} />
        </div>

        {/* Tattoo Style Guide */}
        <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="800">
          <TattooStyleGuide />
        </div>

        {/* Tattoo Process */}
        <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="800">
          <TattooProcess />
        </div>

        {/* Book appointment button */}
        <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="800">
          <BookAppointment handleBookAppointmentClick={handleBookAppointmentClick} />
        </div>

      </div>
    </div>
  );
}

export default ShopHomePage;
