import { getArtistById } from '../models/ArtistModel';
import { fetchUnavailableDates } from './UnavailableDatesController';
import { getShopById, updateShopDetails, getCoordinatesFromAddress } from '../models/ShopModel';
import { fetchArtistServices, updateArtistServices } from '../models/ArtistModel';

// Handle artist selection
export const handleSelectArtist = async (shopId, artistId, token, setSelectedArtist, setError, setUnavailableDates) => {
  try {
    // Fetch artist details
    const artist = await getArtistById(shopId, artistId);

    // Set selected artist
    setSelectedArtist(artist);

    // Fetch unavailable dates for the selected artist
    await fetchUnavailableDates(shopId, artist.name, token, setUnavailableDates, setError);
  } catch (error) {
    // Ensure setError is used correctly
    if (setError) {
      setError('Error fetching artist details');
    }
    console.error('Error fetching artist details:', error);
  }
};


// Fetch shop details
export const handleFetchShopDetails = async (shopId, token, setShopDetails, setErrorMessage) => {
  try {
    const data = await getShopById(shopId, token);
    setShopDetails({
      name: data.name,
      address: data.address,
      phoneNumber: data.phoneNumber,
      email: data.email,
      openingHours: data.openingHours,
      bio: data.bio,
      location: {
        coordinates: {
          lat: data.location?.coordinates?.lat || '',
          long: data.location?.coordinates?.long || '',
        },
        address: data.location?.address || '',
      },
    });
    setErrorMessage('');
  } catch (err) {
    setErrorMessage('Failed to load shop details.');
    console.error('Error fetching shop details:', err);
  }
};

// Update shop details
export const handleUpdateShopDetails = async (
  shopId,
  shopDetails,
  token,
  setShopDetails,
  setErrorMessage,
  setSuccessMessage,
  setIsEditing
) => {
  try {
    // Get coordinates for the address
    const coordinates = await getCoordinatesFromAddress(shopDetails.address);
    const updatedDetails = {
      ...shopDetails,
      location: {
        address: shopDetails.address,
        coordinates: {
          lat: parseFloat(coordinates.lat),
          long: parseFloat(coordinates.long),
        },
      },
    };

    // Update the shop details
    const updatedData = await updateShopDetails(shopId, updatedDetails, token);
    setShopDetails({
      ...updatedData,
      location: {
        coordinates: {
          lat: updatedData.location?.coordinates?.lat || '',
          long: updatedData.location?.coordinates?.long || '',
        },
        address: updatedData.address || '',
      },
    });
    setSuccessMessage('Shop details updated successfully.');
    setIsEditing(false);
  } catch (err) {
    setErrorMessage('Failed to update shop details.');
    console.error('Error updating shop details:', err);
  }
};

// Fetch artist services for the shop
export const handleFetchArtistServices = async (shopId, token, setServices, setErrorMessage) => {
  try {
    const services = await fetchArtistServices(shopId, token);
    setServices(services);
    setErrorMessage('');
  } catch (error) {
    setErrorMessage('Failed to fetch artist services.');
    console.error('Error fetching artist services:', error);
  }
};

// Update artist services for the shop
export const handleUpdateArtistServices = async (shopId, artistId, updatedServices, token, setServices, setErrorMessage) => {
  try {
    const services = await updateArtistServices(shopId, artistId, updatedServices, token);
    setServices(services);
    setErrorMessage('');
  } catch (error) {
    setErrorMessage('Failed to update artist services.');
    console.error('Error updating artist services:', error);
  }
};