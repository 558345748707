import React from 'react';

const BookAppointment = ({ handleBookAppointmentClick }) => {
  return (
    <div className="text-center mb-10">
      <button
        onClick={handleBookAppointmentClick}
        className="bg-indigo-600 text-white py-2 px-6 rounded-lg hover:bg-indigo-700 transition transform hover:scale-105"
      >
        Book an Appointment
      </button>
    </div>
  );
};

export default BookAppointment;
