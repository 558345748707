import React, { useState, useMemo } from 'react';
import ConfirmationModal from './ConfirmationModal';

function AppointmentsTable({ appointments, artists, onDelete }) {
  const [selectedArtist, setSelectedArtist] = useState('');

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);

  // Handle artist selection change
  const handleArtistChange = (event) => {
    setSelectedArtist(event.target.value);
  };

  // Filter appointments based on selected artist
  const filteredAppointments = useMemo(() => {
    return appointments.filter((appointment) => {
      if (!selectedArtist) return true; // If no artist is selected, show all appointments
      return appointment.artistId && appointment.artistId._id === selectedArtist;
    });
  }, [appointments, selectedArtist]);

  // Handle Delete Button Click
  const handleDeleteClick = (appointmentId) => {
    setAppointmentToDelete(appointmentId);
    setIsModalOpen(true);
  };

  // Confirm Deletion
  const handleConfirmDelete = () => {
    onDelete(appointmentToDelete);
    setIsModalOpen(false);
    setAppointmentToDelete(null);
  };

  // Cancel Deletion
  const handleCancelDelete = () => {
    setIsModalOpen(false);
    setAppointmentToDelete(null);
  };

  return (
    <div className="w-full bg-white shadow-md rounded-lg p-6 overflow-x-auto">
      {/* Filter by Artist */}
      <div className="mb-4 flex justify-between items-center">
        <label htmlFor="artist-filter" className="text-lg font-medium text-gray-700">
          Filter by Artist:
        </label>
        <select
          id="artist-filter"
          value={selectedArtist}
          onChange={handleArtistChange}
          className="py-2 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <option value="">All Artists</option>
          {artists.map((artist) => (
            <option key={artist._id} value={artist._id}>
              {artist.name}
            </option>
          ))}
        </select>
      </div>

      {/* Appointments Table */}
      <table className="w-full table-auto bg-white border-collapse rounded-lg text-left">
        <thead>
          <tr className="bg-gray-200 text-left">
            <th className="px-2 py-2">Title</th>
            <th className="px-2 py-2 hidden md:table-cell">First Name</th>
            <th className="px-2 py-2 hidden md:table-cell">Last Name</th>
            <th className="px-2 py-2">Date</th>
            <th className="px-2 py-2">Start Time</th>
            <th className="px-2 py-2 hidden lg:table-cell">End Time</th>
            <th className="px-2 py-2 hidden lg:table-cell">Description</th>
            <th className="px-2 py-2 hidden xl:table-cell">Phone Number</th>
            <th className="px-2 py-2">Artist Name</th>
            <th className="px-2 py-2 hidden lg:table-cell">Service</th>
            <th className="px-2 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAppointments.length > 0 ? (
            filteredAppointments.map((appointment) => (
              <tr key={appointment._id} className="border-t">
                <td className="px-2 py-2">{appointment.title}</td>
                <td className="px-2 py-2 hidden md:table-cell">{appointment.firstName}</td>
                <td className="px-2 py-2 hidden md:table-cell">{appointment.lastName}</td>
                <td className="px-2 py-2">
                  {new Date(appointment.date).toLocaleDateString()}
                </td>
                <td className="px-2 py-2">
                  {new Date(appointment.startTime).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </td>
                <td className="px-2 py-2 hidden lg:table-cell">
                  {appointment.endTime
                    ? new Date(appointment.endTime).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                    : 'N/A'}
                </td>
                <td className="px-2 py-2 hidden lg:table-cell">{appointment.description || 'No description'}</td>
                <td className="px-2 py-2 hidden xl:table-cell">{appointment.phoneNumber}</td>
                <td className="px-2 py-2">
                  {appointment.artistId ? appointment.artistId.name : 'Unknown Artist'}
                </td>
                <td className="px-2 py-2 hidden lg:table-cell">{appointment.selectedService}</td>
                <td className="px-2 py-2">
                  <button
                    className="bg-red-500 text-white py-1 px-3 rounded-lg hover:bg-red-600 transition"
                    onClick={() => handleDeleteClick(appointment._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11" className="px-4 py-4 text-center">
                No appointments found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isModalOpen}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </div>
  );
}

export default AppointmentsTable;
