import React, { useState, useEffect } from 'react';
import {
  fetchGallery,
  uploadImages,
  deleteImage,
} from '../controllers/ShopController';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the reusable spinner

function ShopImageUpload({ shopId, token, recommendedSize }) {
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');
  const [gallery, setGallery] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false); // New loading state
  const [loading, setLoading] = useState(true); // Loading state
  const imagesPerPage = 4;

  // Fetch the gallery on component mount
  useEffect(() => {
    // Only fetch the gallery when shopId is available and valid
    if (shopId && token) {
      setLoading(true); // Show loading while fetching the gallery
      fetchGallery(shopId, token, setGallery, setError)
        .finally(() => setLoading(false)); // Stop loading when the fetch is complete
    }
  }, [shopId, token]);

  // Handle image selection
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages(files);

    const previewList = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(previewList);
    setUploadStatus('');
  };

  // Handle image upload
  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (!selectedImages.length) {
      setUploadStatus('Please select images to upload.');
      return;
    }

    setIsUploading(true); // Set loading state to true
    try {
      await uploadImages(shopId, selectedImages, token, setGallery, setUploadStatus, setError);
    } finally {
      setIsUploading(false); // Reset loading state after upload
    }
  };

  // Handle image delete
  const handleImageDelete = (imagePath) => {
    deleteImage(shopId, imagePath, token, gallery, setGallery, setUploadStatus, setError);
  };

  // Pagination logic
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = gallery.slice(indexOfFirstImage, indexOfLastImage);
  const totalPages = Math.ceil(gallery.length / imagesPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="shop-image-upload mb-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <h4 className="text-lg font-semibold mb-4">Upload Shop Images</h4>
      <p className="mb-2 text-sm text-gray-600">{recommendedSize}</p>
      <form onSubmit={handleImageUpload}>
        <input type="file" multiple onChange={handleImageChange} className="mb-4" />
        <div className="flex flex-wrap gap-4 mb-4">
          {previewImages.map((preview, index) => (
            <img key={index} src={preview} alt="Preview" className="w-32 h-32 object-cover rounded-lg" />
          ))}
        </div>

        <button
          type="submit"
          className={`bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-600 transition ${
            isUploading ? 'bg-gray-500 cursor-not-allowed' : ''
          }`}
          disabled={isUploading} // Disable button when uploading
        >
          {isUploading ? <LoadingSpinner /> : 'Upload Images'} {/* Use reusable spinner */}
        </button>
      </form>

      {/* Gallery Display */}
      {gallery.length > 0 && (
        <div className="mt-8">
          <h4 className="text-lg font-semibold mb-4">Gallery</h4>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            {currentImages.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={`${image}`}
                  alt={`Gallery Image ${index + 1}`}
                  className="w-full h-32 object-cover rounded-lg"
                />
                <button
                  onClick={() => handleImageDelete(image)}
                  className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-center mt-6">
            {Array.from({ length: totalPages }, (_, index) => index + 1).map((number) => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={`mx-1 px-3 py-1 rounded-full ${
                  number === currentPage ? 'bg-indigo-500 text-white' : 'bg-gray-300 text-gray-700'
                }`}
                disabled={number === currentPage}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      )}

      {uploadStatus && <p className="mt-4">{uploadStatus}</p>}
      {error && <p className="text-red-500 mt-4">{error}</p>}
    </div>
  );
}

export default ShopImageUpload;
