// src/controllers/RegisterController.js
import { registerUser } from '../models/RegisterModel';

export const handleRegister = async (
  shopUsername,
  username,
  password,
  confirmPassword,
  accessCode,
  setError,
  setSuccess,
  navigate
) => {
  // Check if passwords match
  if (password !== confirmPassword) {
    setError('Passwords do not match');
    return;
  }

  try {
    // Call the model to perform the registration
    const response = await registerUser(shopUsername, username, password, accessCode);

    // If registration is successful
    if (response.status === 201) {
      setSuccess('Registration successful! Redirecting to login...');
      setTimeout(() => {
        navigate(`/login`);
      }, 2000);
    } else {
      setError('Registration failed. Please try again.');
    }
  } catch (err) {
    setError(err.response?.data?.message || 'Error during registration.');
  }
};
