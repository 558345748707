import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ShopContext } from './context/ShopContext'; // Use ShopContext
import Footer from './components/Footer';
import Header from './components/Header';
import ShopWrapper from './components/ShopWrapper';

//import HomePage from './views/HomePage';
import LoginPage from './views/Login';
import RegisterPage from './views/Register';
import CreateShop from './views/CreateShop';
import SettingsPage from './views/Settings';
import ManagementPage from './views/Management';
import AppointmentCalendar from './views/AppointmentCalendar';
import ArtistsPage from './views/ArtistPage';
import NotFoundPage from './views/NotFoundPage';
import ArtistPage from './views/ArtistPage';
import TattooStyleDetails from './components/TattooStyleDetails';
import ShopHomePage from './views/ShopHomePage';
import ShopMapPage from './views/ShopMapPage';
import HomePage from './views/HomePage';

import { initializeShopContext } from './controllers/AppController'; // Use controller logic

import './App.css';

function App() {
  const {
    token,
    isLoggedIn,
    setToken,
    setShopUsername,
    setShopId,
    setIsLoggedIn,
  } = useContext(ShopContext);

  // Use the controller to initialize the context
  useEffect(() => {
    initializeShopContext(setToken, setShopUsername, setShopId, setIsLoggedIn);
  }, [setToken, setShopUsername, setShopId, setIsLoggedIn]);

  return (
    <Router>
      {/* Header */}
      <Header isLoggedIn={isLoggedIn} className="bg-gray-800 text-white p-4" />

      {/* Main content container */}
      <main className="min-h-screen bg-gray-100">
        <Routes>
          {/* Static routes */}
          
          <Route path="/" element={<ShopHomePage />} />
          <Route path="/create-shop" element={<CreateShop />} />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/styles/:styleId" element={<TattooStyleDetails />} /> 

          {/* Dynamic routes based on shop username */}
          <Route path="/:shopUsername/*" element={<ShopWrapper />}>
            <Route index element={<ShopHomePage />} />
            <Route path="book" element={<AppointmentCalendar />} />
            <Route path="artist/:artistId" element={<ArtistPage />} />
            <Route
              path="management"
              element={isLoggedIn ? <ManagementPage token={token} /> : <LoginPage />}
            />
            <Route
              path="settings"
              element={isLoggedIn ? <SettingsPage token={token} /> : <LoginPage />}
            />
            <Route path="artists" element={<ArtistsPage />} />
          </Route>

          {/* Fallback route for 404 */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>

      {/* Footer */}
      <Footer isLoggedIn={isLoggedIn} className="bg-gray-800 text-white p-4" />
    </Router>
  );
}

export default App;
