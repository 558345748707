// FeaturedArtwork.js
import React, { useState, useEffect, useContext } from 'react';
import Slider from 'react-slick'; // Import the slider
import { ShopContext } from '../context/ShopContext'; // Import ShopContext to access shopUsername
import { handleFetchArtists } from '../controllers/ArtistController'; // Import the controller

function FeaturedArtwork({ shopId }) {
  const [artists, setArtists] = useState([]); // Initialize artists as an array
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(''); // Add error state
  const { shopUsername } = useContext(ShopContext); // Get the shopId and shopUsername from context

  useEffect(() => {
    if (shopId) {
      handleFetchArtists(shopId, setArtists, setLoading, setError);
    } else {
      setError('Shop ID not found.');
      setLoading(false);
    }
  }, [shopId]);

  const getRandomImage = (gallery) => {
    if (!gallery || gallery.length === 0) return null;
    const randomIndex = Math.floor(Math.random() * gallery.length);
    return gallery[randomIndex];
  };

  if (loading) {
    return <div className="text-center text-gray-500">Loading featured artworks...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  if (!Array.isArray(artists) || artists.length === 0) {
    return <div className="text-center text-gray-500">No featured artwork available.</div>;
  }

  // Slider settings
  const sliderSettings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite scroll
    speed: 500, // Transition speed
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Automatically move to the next slide
    autoplaySpeed: 3000, // Auto-move every 3 seconds
    arrows: false, // Hide navigation arrows
    responsive: [
      {
        breakpoint: 1024, // For screen widths < 1024px
        settings: {
          slidesToShow: 2, // Show 2 slides at a time
        },
      },
      {
        breakpoint: 768, // For screen widths < 768px
        settings: {
          slidesToShow: 1, // Show 1 slide at a time
        },
      },
    ],
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-10">
      <h2 className="text-3xl font-bold text-center mb-6 animate-fadeIn">Featured Artwork</h2>

      {artists.length >= 3 ? (
        <Slider {...sliderSettings}>
          {artists.map((artist) => {
            const randomImage = getRandomImage(artist.gallery);

            return (
              <a
                key={artist._id}
                href={`/${shopUsername}/artist/${artist._id}`}
                className="block p-4 animate-slideInUp transition-transform duration-300 hover:scale-105"
              >
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
                  <h3 className="text-2xl font-semibold mb-4 text-center">{artist.name}</h3>

                  {randomImage ? (
                    <div className="relative">
                      <img
                        src={`${randomImage}`} // Display a random image from the gallery
                        alt={artist.name}
                        className="h-64 w-full object-cover rounded-md mb-4 transform transition-transform duration-300 hover:scale-105"
                      />
                    </div>
                  ) : (
                    <div className="h-64 w-full bg-gray-200 flex items-center justify-center rounded-md mb-4">
                      <p className="text-gray-500">No artwork available</p>
                    </div>
                  )}

                  <div className="text-center">
                    <p className="text-gray-600 mb-2">
                      {artist.bio ? artist.bio.substring(0, 100) + '...' : 'No bio available'}
                    </p>
                    <span
                      className="bg-indigo-600 text-white py-2 px-4 rounded-lg transition-transform duration-300 hover:bg-indigo-700"
                    >
                      View Artist
                    </span>
                  </div>
                </div>
              </a>
            );
          })}
        </Slider>
      ) : (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {artists.map((artist) => {
            const randomImage = getRandomImage(artist.gallery);

            return (
              <a
                key={artist._id}
                href={`/${shopUsername}/artist/${artist._id}`}
                className="block p-4 animate-slideInUp transition-transform duration-300 hover:scale-105"
              >
                <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg">
                  <h3 className="text-2xl font-semibold mb-4 text-center">{artist.name}</h3>

                  {randomImage ? (
                    <div className="relative">
                      <img
                        src={`${randomImage}`} // Display a random image from the gallery
                        alt={artist.name}
                        className="h-64 w-full object-cover rounded-md mb-4 transform transition-transform duration-300 hover:scale-105"
                      />
                    </div>
                  ) : (
                    <div className="h-64 w-full bg-gray-200 flex items-center justify-center rounded-md mb-4">
                      <p className="text-gray-500">No artwork available</p>
                    </div>
                  )}

                  <div className="text-center">
                    <p className="text-gray-600 mb-2">
                      {artist.bio ? artist.bio.substring(0, 100) + '...' : 'No bio available'}
                    </p>
                    <span
                      className="bg-indigo-600 text-white py-2 px-4 rounded-lg transition-transform duration-300 hover:bg-indigo-700"
                    >
                      View Artist
                    </span>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default FeaturedArtwork;
