// models/GalleryModel.js
import axiosInstance from '../services/axiosInstance';

// Fetch the gallery for a specific artist
export const fetchArtistGallery = async (shopId, artistId, token) => {
  const response = await axiosInstance.get(`/shop/${shopId}/artists/${artistId}/gallery`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.gallery;
};

// Upload new images to the artist's gallery
export const uploadGalleryImages = async (shopId, artistId, formData, token) => {
  const response = await axiosInstance.post(`/shop/${shopId}/artists/${artistId}/gallery`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Delete an image from the gallery
export const deleteGalleryImage = async (shopId, artistId, imageFilename, token) => {
  try {
    const response = await axiosInstance.delete(`/shop/${shopId}/artists/${artistId}/gallery`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { imageId: imageFilename },  // Send imageFilename as a query parameter
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting gallery image:', error.response?.data || error.message);
    throw error;  // Re-throw the error so it can be handled by the caller
  }
};

