// models/ReviewModel.js
import axiosInstance from '../services/axiosInstance';

// Fetch reviews for a specific artist
export const fetchReviewsForArtist = async (artistId) => {
  const response = await axiosInstance.get(`/artists/${artistId}/reviews`);
  return response.data; // Return the list of reviews
};

// Submit a review for an artist
export const submitReview = async (artistId, formData) => {
    const response = await axiosInstance.post(`/artists/${artistId}/reviews`, formData);
    return response.data;
  };

// Delete a review for a specific artist
export const deleteReview = async (artistId, reviewId) => {
    const response = await axiosInstance.delete(`/artists/${artistId}/reviews/${reviewId}`);
    return response.data;
  };