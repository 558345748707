// ArtistGallery.js
import React, { useState, useEffect } from 'react';
import { handleFetchGallery } from '../controllers/GalleryController';

function ArtistGallery({ artistId, shopId }) {
  const [gallery, setGallery] = useState([]);
  const [error, setError] = useState(null); // Error state
  const [selectedImage, setSelectedImage] = useState(null); // State to manage the clicked image

  // Fetch artist's gallery
  useEffect(() => {
    if (artistId && shopId) {
      handleFetchGallery(shopId, artistId, setGallery, setError);
    } else {
      setError('Missing artistId or shopId.');
    }
  }, [artistId, shopId]);

  // Function to handle when an image is clicked
  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the clicked image as the selected image to display in the modal
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedImage(null); // Close the modal by setting selectedImage to null
  };

  return (
    <div className="artist-gallery">
      <h2 className="text-2xl font-bold mb-4">Gallery</h2>

      {/* Display error messages */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Display gallery images */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {gallery.length > 0 ? (
          gallery.map((image, index) => (
            <img
              key={index}
              src={`${image}`} // Prepend the correct base URL
              alt={`Gallery image ${index + 1}`}
              className="gallery-image cursor-pointer rounded-lg shadow-lg hover:scale-105 transition-transform duration-300"
              onClick={() => handleImageClick(image)} // Open the modal with the clicked image
              onError={() => console.error('Image failed to load:', image)} // Handle image loading error
            />
          ))
        ) : (
          <p className="text-gray-500">No images uploaded yet.</p>
        )}
      </div>

      {/* Modal for displaying the clicked image */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={closeModal}>
          <div className="relative bg-white p-4 rounded-lg shadow-lg">
            <span
              className="absolute top-2 right-2 text-white text-2xl cursor-pointer"
              onClick={closeModal}
            >
              &times;
            </span>
            <img
              src={`${selectedImage}`}
              alt="Expanded artist"
              className="max-w-full max-h-screen rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ArtistGallery;
