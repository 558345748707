// views/SatisfactionScore.js
import React, { useEffect, useState } from 'react';
import { calculateAverageScore } from '../controllers/ReviewController';

const SatisfactionScore = ({ shopId }) => {
  const [averageScore, setAverageScore] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch and calculate the average score when the component mounts
  useEffect(() => {
    calculateAverageScore(shopId, setAverageScore, setTotalReviews, setLoading, setError);
  }, [shopId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div className="p-4 bg-white shadow-md rounded-lg mt-4">
      <h2 className="text-2xl font-bold mb-4">Satisfaction Score</h2>
      {totalReviews > 0 ? (
        <>
          <p className="text-4xl font-semibold text-green-600">
            {averageScore.toFixed(1)} / 5
          </p>
          <p>Total Reviews: {totalReviews}</p>
        </>
      ) : (
        <p>No reviews yet.</p>
      )}
    </div>
  );
};

export default SatisfactionScore;
