// ArtistPage.js
import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ShopContext } from '../context/ShopContext';
import ArtistGallery from '../components/ArtistGallery';
import ReviewForm from '../components/ReviewForm';
import ReviewList from '../components/ReviewList';

import { fetchArtistData } from '../controllers/ArtistController';

function ArtistPage() {
  const { artistId } = useParams();
  const [artist, setArtist] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [availableStyles, setAvailableStyles] = useState([]); // Store available styles
  const { shopId } = useContext(ShopContext);

  useEffect(() => {
    if (shopId) {
      fetchArtistData(shopId, artistId, setArtist, setAvailableStyles, setLoading, setError);
    } else {
      setError('Shop ID is missing');
      setLoading(false);
    }
  }, [shopId, artistId]);

  const formatBio = (bio) => {
    return bio.split('\n').map((paragraph, idx) => <p key={idx}>{paragraph}</p>);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="spinner">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10">
        <h2 className="text-2xl font-bold text-red-500">Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
      {/* Artist Header */}
      <div className="flex flex-col items-center mb-6">
        <h1 className="text-4xl font-bold mb-4">{artist.name}</h1>

        {artist.image && (
          <img
          src={`${artist.image}`}
          alt={artist.name}
          className="w-48 h-48 rounded-full object-cover shadow-md"
        />

        )}
      </div>

      {/* Artist Details */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div className="artist-bio">
          <h2 className="text-2xl font-semibold mb-2">Bio</h2>
          <div className="text-gray-700 leading-relaxed">
            {artist.bio ? formatBio(artist.bio) : <p>This artist has an amazing portfolio!</p>}
          </div>
        </div>

        {artist.socialMedia?.instagram && (
          <div className="artist-social-media">
            <h2 className="text-2xl font-semibold mb-2">Instagram</h2>
            <p>
              <a
                href={`https://instagram.com/${artist.socialMedia.instagram}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800"
              >
                @{artist.socialMedia.instagram}
              </a>
            </p>
          </div>
        )}

        {artist.styles && artist.styles.length > 0 && (
          <div className="artist-styles">
            <h2 className="text-2xl font-semibold mb-2">Styles</h2>
            <ul className="list-disc list-inside text-gray-700">
              {artist.styles
                .filter((style) => style.name !== 'Unknown Style') // Only show styles that are properly mapped
                .map((style) => (
                  <li key={style._id}>
                    <Link to={`/styles/${style._id}`} className="text-blue-600 hover:underline">
                      {style.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        )}

        {artist.services && artist.services.length > 0 && (
          <div className="artist-services">
            <h2 className="text-2xl font-semibold mb-2">Services</h2>
            <ul className="list-disc list-inside text-gray-700">
              {artist.services.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Artist Gallery */}
      <div className="artist-gallery mt-10 mb-12">
        <ArtistGallery artistId={artistId} shopId={shopId} />
      </div>

      {/* Review List */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Customer Reviews</h2>
        <ReviewList artistId={artistId} />
      </div>

      {/* Review Form */}
      <div className="bg-gray-50 p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-semibold mb-4">Leave a Review</h2>
        <ReviewForm artistId={artistId} />
      </div>
    </div>
  );
}

export default ArtistPage;
