// src/controllers/ShopHomeController.js
import axiosInstance from '../services/axiosInstance';  // WIP
import { getShopByUsername, fetchArtistsForShop, fetchArtistReviews } from '../models/ShopModel';

export const initializeShopData = async (
  shopUsername,
  setShopId,
  setShopUsername,
  setShopDetails,
  setCarouselImages,
  setArtists,
  setReviews
) => {
  try {
    // Fetch shop details by username
    const shop = await getShopByUsername(shopUsername); // Corrected to getShopByUsername from ShopModel.js

    if (shop) {
      // Set the shop details, including ID, username, and gallery images
      setShopDetails(shop);
      setShopId(shop._id);
      setShopUsername(shop.username);
      setCarouselImages(shop.gallery || []);

      // Fetch artists for the shop
      const artists = await fetchArtistsForShop(shop._id);
      setArtists(artists);

      // Fetch reviews for each artist and include artist names with reviews
      const allReviews = await Promise.all(
        artists.map(async (artist) => {
          try {
            const reviews = await fetchArtistReviews(artist._id);
            // Ensure that reviews is an array before mapping over it
            if (Array.isArray(reviews) && reviews.length > 0) {
              return reviews.map((review) => ({ ...review, artistName: artist.name }));
            }
            return []; // Return an empty array if there are no reviews for this artist
          } catch (error) {
            if (error.response && error.response.status === 404) {
              console.warn(`No reviews found for artist: ${artist.name}`);
              return []; // Return an empty array if the artist's reviews result in a 404 error
            } else {
              console.error(`Error fetching reviews for artist ${artist.name}:`, error);
              return []; // Return an empty array on other errors
            }
          }
        })
      );

      // Flatten the array of reviews to ensure it's a single array
      const flattenedReviews = allReviews.flat();

      // Flatten the array of reviews and filter only five-star reviews
      const allFiveStarReviews = flattenedReviews.filter((review) => review.rating === 5);

      setReviews(allFiveStarReviews);

    }
  } catch (error) {
    console.error('Failed to fetch shop details or artists:', error);
  }
};

// WIP
export const initializeFirstShop = async () => {
  try {
    // Make an API call to get the first shop
    const response = await axiosInstance.get('/shop/first-shop');
    const firstShop = response.data;

    if (firstShop) {
      // Store the shop's information in localStorage (or wherever necessary)
      localStorage.setItem('shopUsername', firstShop.username);
      localStorage.setItem('shopId', firstShop._id);

      // Return the shop data for further use if needed
      return {
        shopUsername: firstShop.username,
        shopId: firstShop._id,
      };
    } else {
      console.warn('No shops found in the database.');
      return null;
    }
  } catch (error) {
    console.error('Error initializing the first shop:', error.message);
    throw new Error('Failed to initialize the first shop.');
  }
};