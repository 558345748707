import React, { useState, useEffect } from 'react';
import { handleFetchGallery, handleUploadImages, handleDeleteImage } from '../controllers/GalleryController';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the reusable spinner

function ArtistGalleryUpload({ artistId, shopId, token }) {
  const [selectedFiles, setSelectedFiles] = useState(null); // State for selected files
  const [gallery, setGallery] = useState([]); // State to store gallery images
  const [error, setError] = useState('');
  const [showGallery, setShowGallery] = useState(true); // State to toggle gallery visibility
  const [isUploading, setIsUploading] = useState(false); // Loading state

  // Fetch artist's gallery when component is mounted
  useEffect(() => {
    if (artistId && shopId) {
      handleFetchGallery(shopId, artistId, setGallery, setError);
    }
  }, [artistId, shopId]);

  // Handle file selection
  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  // Handle image upload
  const handleUpload = async (e) => {
    e.preventDefault();
    if (selectedFiles && shopId && artistId && token) {
      setIsUploading(true); // Set loading state to true
      try {
        await handleUploadImages(shopId, artistId, selectedFiles, token, setGallery, setError, setSelectedFiles);
      } finally {
        setIsUploading(false); // Reset loading state
      }
    } else {
      setError('No files selected or invalid artist/shop.');
    }
  };

  // Handle image deletion
  const handleDelete = (imageFilename) => {
    if (shopId && artistId && token) {
      handleDeleteImage(shopId, artistId, imageFilename, token, gallery, setGallery, setError);
    }
  };

  // Toggle gallery visibility
  const toggleGallery = () => {
    setShowGallery(!showGallery);
  };

  return (
    <div className="artist-gallery-upload bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Artist Gallery</h2>

      {/* Display error message */}
      {error && <p className="text-red-500 mb-4">{error}</p>}

      {/* Image Upload Form */}
      <form onSubmit={handleUpload} className="mb-4">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="block w-full text-gray-700 py-2 px-4 mb-2 border border-gray-300 rounded-lg"
        />
        <button
          type="submit"
          className={`bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition ${
            isUploading ? 'bg-gray-500 cursor-not-allowed' : ''
          }`}
          disabled={isUploading} // Disable button while uploading
        >
          {isUploading ? <LoadingSpinner /> : 'Upload Images'} {/* Display spinner during upload */}
        </button>
      </form>

      {/* Hide/Show Button */}
      <button
        className="mb-4 bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition"
        onClick={toggleGallery}
      >
        {showGallery ? 'Hide Gallery' : 'Show Gallery'}
      </button>

      {/* Display the gallery */}
      {showGallery && gallery.length > 0 ? (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {gallery.map((image, index) => (
            <div key={index} className="relative">
              <img
                src={`${image}`}
                alt={`Gallery Image ${index + 1}`}
                className="rounded-lg w-full h-40 object-cover"
              />
              <button
                className="absolute top-2 right-2 bg-red-500 text-white py-1 px-2 rounded-full hover:bg-red-600 transition"
                onClick={() => handleDelete(image)}
              >
                ✖
              </button>
            </div>
          ))}
        </div>
      ) : !showGallery ? null : (
        <p className="text-gray-700">No images in the gallery yet.</p>
      )}
    </div>
  );
}

export default ArtistGalleryUpload;
