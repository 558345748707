import React, { useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal';

const localizer = momentLocalizer(moment);

Modal.setAppElement('#root');

function AppointmentsCalendarView({ appointments, artists }) {
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState('');

  // Handle event click to show appointment details
  const handleSelectEvent = (appointment) => {
    setSelectedAppointment(appointment);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAppointment(null);
  };

  // Transform appointments into the format required by the calendar
  const events = useMemo(() => {
    return appointments
      .filter((appointment) => {
        if (!selectedArtist) return true; // Show all appointments if no artist is selected
        return appointment.artistId && appointment.artistId._id === selectedArtist;
      })
      .map((appointment) => {
        const startDate = new Date(appointment.startTime);
        const endDate = appointment.endTime ? new Date(appointment.endTime) : new Date(startDate);

        return {
          title: appointment.title || 'No Title',
          start: startDate,
          end: endDate,
          allDay: false,
          description: appointment.description || 'No description',
          artistName: appointment.artistId ? appointment.artistId.name : 'Unknown Artist',
          phoneNumber: appointment.phoneNumber,
          selectedService: appointment.selectedService,
          firstName: appointment.firstName,
          lastName: appointment.lastName,
        };
      });
  }, [appointments, selectedArtist]);

  return (
    <div className="container mx-auto mt-10">
      {/* Artist Filter Dropdown */}
      <div className="mb-6">
        <label className="block text-gray-700 font-semibold mb-2">Filter by Artist:</label>
        <select
          value={selectedArtist}
          onChange={(e) => setSelectedArtist(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg"
        >
          <option value="">All Artists</option>
          {artists.map((artist) => (
            <option key={artist._id} value={artist._id}>
              {artist.name}
            </option>
          ))}
        </select>
      </div>

      {/* Calendar Component */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onSelectEvent={handleSelectEvent}
        />
      </div>

      {/* Modal for Appointment Details */}
      {selectedAppointment && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Appointment Details"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
          overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-40"
        >
          <div className="bg-white max-w-lg w-full mx-auto p-6 rounded-lg shadow-lg relative">
            <h2 className="text-xl font-bold mb-4">Appointment Details</h2>
            <p className="mb-2"><strong>Title:</strong> {selectedAppointment.title}</p>
            <p className="mb-2"><strong>Date:</strong> {moment(selectedAppointment.start).format('MMMM Do YYYY, h:mm A')}</p>
            <p className="mb-2"><strong>Artist:</strong> {selectedAppointment.artistName}</p>
            <p className="mb-2"><strong>Client Name:</strong> {selectedAppointment.firstName} {selectedAppointment.lastName}</p>
            <p className="mb-2"><strong>Service:</strong> {selectedAppointment.selectedService}</p>
            <p className="mb-2"><strong>Phone Number:</strong> {selectedAppointment.phoneNumber}</p>
            <p className="mb-4"><strong>Description:</strong> {selectedAppointment.description}</p>
            <button
              onClick={closeModal}
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition w-full"
            >
              Close
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default AppointmentsCalendarView;
