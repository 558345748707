// Footer.js
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ShopContext } from '../context/ShopContext';
import { handleFetchShopDetails, handleLogout } from '../controllers/ShopController';

function Footer({ isLoggedIn }) {
  const { shopUsername, setIsLoggedIn, setToken, setShopUsername, setShopId } = useContext(ShopContext);
  const [shopDetails, setShopDetails] = useState({
    name: 'Tattoo Scheduling',
    bio: 'Our platform allows you to easily schedule appointments with your favorite artists.',
    email: 'info@tattooschedule.com',
    phoneNumber: '(555) 123-4567',
    address: '123 Tattoo Ave, Ink City',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Fetch shop details when the component mounts
  useEffect(() => {
    if (shopUsername) {
      handleFetchShopDetails(shopUsername, setShopDetails, setError);
    }
  }, [shopUsername]);

  // Function to truncate bio text
  const truncateBio = (bio, maxLength) => {
    if (bio.length > maxLength) {
      return bio.substring(0, maxLength) + '...';
    }
    return bio;
  };

  return (
    <footer className="bg-gray-900 text-white py-8 fade-in">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 animate-fade-up">
        {/* About Section */}
        <div className="footer-section">
          <h4 className="text-xl font-bold mb-4 slide-up">About {shopDetails?.name || 'Tattoo Scheduling'}</h4>
          <p className="text-gray-400">
            {shopDetails?.bio ? truncateBio(shopDetails.bio, 100) : 'Our platform allows you to easily schedule appointments with your favorite artists.'}
          </p>
        </div>

        {/* Contact Section */}
        <div className="footer-section">
          <h4 className="text-xl font-bold mb-4 slide-up">Contact Us</h4>
          {error && <p className="text-red-500">{error}</p>}
          <p className="text-gray-400">Email: {shopDetails?.email}</p>
          <p className="text-gray-400">Phone: {shopDetails?.phoneNumber}</p>
          <p className="text-gray-400">Address: {shopDetails?.address}</p>
        </div>

        {/* Management Section */}
        <div className="footer-section">
          <h4 className="text-xl font-bold mb-4 slide-up">Management</h4>
          {isLoggedIn ? (
            <button
              className="mt-4 py-2 px-4 bg-red-500 hover:bg-red-600 text-white rounded transition-transform transform hover:scale-105"
              onClick={() => handleLogout(setIsLoggedIn, setToken, setShopUsername, setShopId, navigate)}
            >
              Logout
            </button>
          ) : (
            <>
              <p className="text-gray-400">
                <Link to="/login" className="hover:text-white hover:underline transition-colors">Login</Link>
              </p>
              <p className="text-gray-400">
                <Link to="/register" className="hover:text-white hover:underline transition-colors">Register</Link>
              </p>
              <p className="text-gray-400">
                <Link to="/create-shop" className="hover:text-white hover:underline transition-colors">Create Shop</Link>
              </p>
            </>
          )}
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="border-t border-gray-700 mt-8 pt-4 text-center fade-in-bottom">
        <p className="text-gray-400">&copy; {new Date().getFullYear()} {shopDetails?.name || 'Tattoo Scheduling'}. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
