import React from 'react';

function Pagination({ totalPages, currentPage, onPageChange }) {
  // Limit the number of pagination buttons shown at a time
  const maxPageButtons = 3; // Adjust this number based on how many buttons you want visible at a time
  const startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  const endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex justify-center items-center space-x-2 mt-4">
      {/* Previous button */}
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-150 ease-in-out ${
          currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''
        }`}
      >
        Previous
      </button>

      {/* Page number buttons */}
      {pageNumbers.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={`px-4 py-2 rounded-md ${
            currentPage === page
              ? 'bg-indigo-600 text-white'
              : 'bg-gray-300 text-gray-700 hover:bg-gray-400'
          } transition duration-150 ease-in-out`}
        >
          {page}
        </button>
      ))}

      {/* Next button */}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-150 ease-in-out ${
          currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''
        }`}
      >
        Next
      </button>
    </div>
  );
}

export default Pagination;
