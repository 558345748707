// src/models/RegisterModel.js
import axiosInstance from '../services/axiosInstance';

// Function to handle the registration API call
export const registerUser = async (shopUsername, username, password, accessCode) => {
  const response = await axiosInstance.post('/auth/register', {
    shopUsername,
    username,
    password,
    accessCode,
  });

  return response;
};
