// src/controllers/LoginController.js
import { loginUser } from '../models/LoginModel';
import {jwtDecode} from 'jwt-decode';

export const handleLogin = async (
  username,
  password,
  shopUsernameInput,
  setToken,
  setShopUsername,
  setShopId,
  setIsLoggedIn,
  navigate,
  setError
) => {
  try {
    const res = await loginUser(username, password, shopUsernameInput);
    const { token } = res;

    if (token) {
      // Decode token to extract shopId and shopUsername
      const decodedToken = jwtDecode(token);
      const shopId = decodedToken.shopId;
      const shopUsernameFromToken = decodedToken.shopUsername;

      // Set token and shop info in context
      setToken(token);
      setShopUsername(shopUsernameFromToken);
      setShopId(shopId);
      setIsLoggedIn(true); // Set login state to true

      // Store in localStorage (or sessionStorage)
      localStorage.setItem('token', token);
      localStorage.setItem('shopUsername', shopUsernameFromToken);
      localStorage.setItem('shopId', shopId);

      // Redirect to the shop's management page
      navigate(`/${shopUsernameFromToken}/management`);
    } else {
      setError('Login failed. Please try again.');
    }
  } catch (err) {
    console.error('Error during login:', err);
    setError('Invalid shop username, username, or password. Please try again.');
  }
};
