// src/models/LoginModel.js
import axiosInstance from '../services/axiosInstance';

// Function to handle the login API call
export const loginUser = async (username, password, shopUsername) => {
  const response = await axiosInstance.post('/auth/login', {
    username,
    password,
    shopUsername,
  });

  return response.data;
};
