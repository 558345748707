import React, { useState, useEffect } from 'react';
import ArtistCard from '../components/ArtistCard';
import UnavailableDates from '../components/UnavailableDates';
import NewArtistForm from '../components/NewArtistForm';
import ArtistGalleryUpload from '../components/ArtistGalleryUpload';
import ShopSettings from '../components/ShopSettings';
import ConfirmationModal from '../components/ConfirmationModal';
import ShopImageUpload from '../components/ShopImageUpload';
import ManageTattooStyles from '../components/ManageTattooStyles';
import { jwtDecode } from 'jwt-decode';

import { fetchArtists, handleAddArtist, handleDeleteArtist } from '../controllers/ArtistController';
import { handleSelectArtist } from '../controllers/SettingsController';
import { handleAddUnavailableDate, handleDeleteUnavailableDate } from '../controllers/UnavailableDatesController';
// Usage inside Settings.js

function Settings({ token }) {
  const [artists, setArtists] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [newUnavailableDate, setNewUnavailableDate] = useState('');
  const [newStartTime, setNewStartTime] = useState('');
  const [newEndTime, setNewEndTime] = useState('');
  const [recurrencePattern, setRecurrencePattern] = useState('');
  const [recurrenceEndDate, setRecurrenceEndDate] = useState('');
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shopId, setShopId] = useState(null);

  // Extract shopId from token
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const shopIdFromToken = decodedToken.shopId || decodedToken.user?.shopId;
        if (shopIdFromToken) {
          setShopId(shopIdFromToken);
        } else {
          setError('shopId not found in token');
        }
      } catch (e) {
        console.error('Failed to decode token:', e);
        setError('Invalid token');
      }
    } else {
      setError('No token provided');
    }
  }, [token]);

  // Fetch artists when shopId becomes available
  useEffect(() => {
    const fetchAndSelectFirstArtist = async () => {
      try {
        // Fetch artists and set state
        const firstSelected = await fetchArtists(shopId, token, setArtists, setSelectedArtist, setError);
        setSelectedArtist(firstSelected);
        if (firstSelected) {
          // Fetch unavailable dates for the first artist
          handleArtistSelect(firstSelected._id);
        }
      } catch (error) {
        setError('Failed to fetch artists.');
      }
    };

    if (shopId) {
      fetchAndSelectFirstArtist();
    }
  }, [shopId, token]);

  // Handle artist selection and fetch details
  const handleArtistSelect = (artistId) => {
    handleSelectArtist(shopId, artistId, token, setSelectedArtist, setError, setUnavailableDates);
  };
  

  return (
    <div className="max-w-7xl mx-auto p-8">
      {error && <p className="text-red-500 mb-4">{error}</p>}

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        {/* Sidebar */}
        <div className="lg:col-span-1 bg-white p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-bold mt-4">Artists</h3>
          <ul className="space-y-2 mt-4">
            {artists.map((artist) => (
              <li key={artist._id}>
                <button
                  className={`block w-full text-left p-2 rounded-lg hover:bg-gray-100 ${
                    selectedArtist?._id === artist._id ? 'bg-gray-200' : ''
                  }`}
                  onClick={() => handleArtistSelect(artist._id)} // Fetch artist details and unavailable dates
                >
                  {artist.name}
                </button>
              </li>
            ))}
          </ul>
          <NewArtistForm
            token={token}
            shopId={shopId}
            onArtistAdd={(newArtist) => handleAddArtist(shopId, token, newArtist, setArtists, setIsSubmitting, setError)}
            isSubmitting={isSubmitting}
          />

          <hr />
          <ShopSettings token={token} />
        </div>

        {/* Main Content */}
        <div className="lg:col-span-3">
          {selectedArtist && (
            <>
              <ArtistCard
                artist={selectedArtist}
                token={token}
                onArtistUpdate={(updatedArtist) => {
                  setArtists((prevArtists) =>
                    prevArtists.map((artist) =>
                      artist._id === updatedArtist._id ? updatedArtist : artist
                    )
                  );
                  setSelectedArtist(updatedArtist);
                }}
              />

              {/* Unavailable Dates Management */}
              <UnavailableDates
                unavailableDates={unavailableDates}
                isEditing={true}
                handleDeleteUnavailableDate={(id) =>
                  handleDeleteUnavailableDate(shopId, id, token, () =>
                    handleSelectArtist(selectedArtist._id), setError
                  )
                }
                newUnavailableDate={newUnavailableDate}
                setNewUnavailableDate={setNewUnavailableDate}
                newStartTime={newStartTime}
                setNewStartTime={setNewStartTime}
                newEndTime={newEndTime}
                setNewEndTime={setNewEndTime}
                handleAddUnavailableDate={() =>
                  handleAddUnavailableDate(
                    shopId,
                    token,
                    {
                      artistName: selectedArtist.name,
                      date: new Date(`${newUnavailableDate}T00:00:00`).toISOString(),
                      startTime: newStartTime,
                      endTime: newEndTime,
                      recurrencePattern,
                      recurrenceEndDate: recurrenceEndDate ? new Date(`${recurrenceEndDate}T00:00:00`).toISOString() : null,
                    },
                    setNewUnavailableDate,
                    setNewStartTime,
                    setNewEndTime,
                    setRecurrencePattern,
                    setRecurrenceEndDate,
                    () => handleSelectArtist(selectedArtist._id),
                    setError
                  )
                }
                recurrencePattern={recurrencePattern}
                setRecurrencePattern={setRecurrencePattern}
                recurrenceEndDate={recurrenceEndDate}
                setRecurrenceEndDate={setRecurrenceEndDate}
              />

              {/* Artist Gallery Upload */}
              <ArtistGalleryUpload
                shopId={shopId}
                artistId={selectedArtist._id}
                token={token}
              />

              {/* Delete Artist Button */}
              <button
                className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition mt-6"
                onClick={() => setIsModalOpen(true)}
              >
                Delete Artist
              </button>
            </>
          )}
        </div>
      </div>

      {/* Shop Images Upload */}
      <ShopImageUpload shopId={shopId} token={token} />

      {/* Confirmation Modal */}
      <ConfirmationModal
          isOpen={isModalOpen}
          onConfirm={() =>
            handleDeleteArtist(
              shopId,
              selectedArtist._id,
              token,
              setArtists,
              setSelectedArtist,
              setError,
              setIsModalOpen // Pass setIsModalOpen here
            )
          }
          onCancel={() => setIsModalOpen(false)}
        />

      <ManageTattooStyles token={token} />
    </div>
  );
}

export default Settings;
