import React, { useState } from 'react';
import axiosInstance from '../services/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { formatPhoneNumber } from '../utils/utils';

function CreateShop() {
  const [shopDetails, setShopDetails] = useState({
    name: '',
    username: '',
    address: '',
    phoneNumber: '',
    email: '',
    openingHours: '',
    bio: '',
    secretKey: '',  // Add secretKey to shopDetails
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Format the phone number if the input field is for the phoneNumber
    if (name === 'phoneNumber') {
      formattedValue = formatPhoneNumber(value);
    }

    setShopDetails({ ...shopDetails, [name]: formattedValue });
    setError('');
    setSuccessMessage('');
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post('/shop/create', shopDetails);
      if (res.status === 201) {
        setSuccessMessage('Shop created successfully!');
        navigate(`/${shopDetails.username}`);
      } else {
        setError('Failed to create shop. Please try again.');
      }
    } catch (err) {
      setError('An error occurred while creating the shop. Please try again.');
    }
  };

  return (
    <div className="max-w-3xl mx-auto bg-white shadow-lg p-8 rounded-lg mt-10">
      <h2 className="text-3xl font-bold mb-6 text-center">Create Shop</h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Shop Name */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Shop Name</label>
          <input
            type="text"
            name="name"
            value={shopDetails.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Enter shop name"
          />
        </div>

        {/* Shop Username */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Shop Username</label>
          <input
            type="text"
            name="username"
            value={shopDetails.username}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Choose a unique username"
          />
        </div>

        {/* Address */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Address</label>
          <input
            type="text"
            name="address"
            value={shopDetails.address}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Enter shop address"
          />
        </div>

        {/* Phone Number */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={shopDetails.phoneNumber}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Enter phone number"
          />
        </div>

        {/* Email */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={shopDetails.email}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Enter email address"
          />
        </div>

        {/* Opening Hours */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Opening Hours</label>
          <input
            type="text"
            name="openingHours"
            value={shopDetails.openingHours}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="e.g. Mon-Fri, 9 AM - 6 PM"
          />
        </div>

        {/* Bio */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Bio</label>
          <textarea
            name="bio"
            value={shopDetails.bio}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Describe your shop"
            rows="3"
          />
        </div>

        {/* Secret Key */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Secret Key</label>
          <input
            type="text"
            name="secretKey"
            value={shopDetails.secretKey}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Enter secret key"
          />
        </div>

        {/* Error Message */}
        {error && <p className="text-red-600 text-sm">{error}</p>}
        {/* Success Message */}
        {successMessage && <p className="text-green-600 text-sm">{successMessage}</p>}

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition w-full"
          >
            Create Shop
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateShop;
