// controllers/ShopController.js
import { getRankedShops, getShopById, getShopByUsername, getLoggedInShopName, getSelectedShopName, getShopGallery, uploadShopImages, deleteShopImage, updateShopDetails, getCoordinatesFromAddress } from '../models/ShopModel';

// Fetch the gallery for a specific shop
export const fetchGallery = async (shopId, token, setGallery, setError) => {
  try {
    // Check if shopId and token are provided
    if (!shopId?.trim() || !token?.trim()) {
      throw new Error('Invalid shopId or missing authentication token.');
    }
    // Attempt to fetch the shop's gallery
    const gallery = await getShopGallery(shopId, token);

    // Check if the gallery data is valid
    if (!gallery || !Array.isArray(gallery)) {
      throw new Error('Invalid gallery data received from the server.');
    }

    // Set the gallery state
    setGallery(gallery);
  } catch (error) {

    // Log the error for debugging
    console.error(`Error fetching shop gallery for shopId: ${shopId}`, error);

    // Check if the error is an API error with a response status code
    if (error.response) {
      const { status } = error.response;
      
      // Handle specific status codes
      if (status === 401) {
        setError('Unauthorized access. Please log in again.');
      } else if (status === 404) {
        setError('Gallery not found for this shop.');
      } else {
        setError('Error fetching shop gallery. Please try again later.');
      }
    } else if (error.request) {
      // Error occurred in making the request but no response was received
      setError('Network error. Please check your connection and try again.');
    } else {
      // Something happened while setting up the request
      setError('Error fetching shop gallery. Please try again later.');
    }
  }
};



// Handle image upload
export const uploadImages = async (shopId, images, token, setGallery, setUploadStatus, setError) => {
  try {
    const updatedGallery = await uploadShopImages(shopId, images, token);
    setGallery(updatedGallery);
    setUploadStatus('Images uploaded successfully!');
  } catch (error) {
    setUploadStatus('Failed to upload images.');
    setError('Error uploading images.');
  }
};

// Handle image deletion
export const deleteImage = async (shopId, imagePath, token, gallery, setGallery, setUploadStatus, setError) => {
  try {
    await deleteShopImage(shopId, imagePath, token);
    setGallery(gallery.filter((image) => image !== imagePath)); // Update the gallery after deletion
    setUploadStatus('Image deleted successfully!');
  } catch (error) {
    setUploadStatus('Failed to delete image.');
    setError('Error deleting image.');
  }
};

// Fetch ranked shops
export const fetchRankedShops = async (setShops, setLoading, setError) => {
  try {
    const data = await getRankedShops();
    setShops(data);
    setLoading(false);
  } catch (err) {
    setError('Failed to load shop data.');
    setLoading(false);
  }
};

// Determine the shop name to display in the header
export const getShopNameForHeader = async (isLoggedIn, shopUsername, token, setShopName) => {
  try {
    if (isLoggedIn && token) {
      const loggedInShopName = await getLoggedInShopName(token);
      setShopName(loggedInShopName);
    } else if (shopUsername) {
      const selectedShopName = await getSelectedShopName(shopUsername);
      setShopName(selectedShopName);
    } else {
      setShopName('Tattoo Scheduling'); // Default shop name
    }
  } catch (error) {
    console.error('Error fetching shop name:', error);
    setShopName('Tattoo Scheduling');
  }
};

// Fetch shop details by shopId
export const handleFetchShopDetailsById = async (shopId, token, setShopDetails, setErrorMessage) => {
  try {
    const data = await getShopById(shopId, token); // Corrected function name
    setShopDetails({
      name: data.name,
      address: data.address,
      phoneNumber: data.phoneNumber,
      email: data.email,
      openingHours: data.openingHours,
      bio: data.bio,
      location: {
        coordinates: {
          lat: data.location?.coordinates?.lat || '',
          long: data.location?.coordinates?.long || '',
        },
        address: data.location?.address || '',
      },
    });
    setErrorMessage('');
  } catch (err) {
    setErrorMessage('Failed to load shop details.');
  }
};

// Submit updated shop details
export const handleSubmitShopDetails = async (
  shopId,
  shopDetails,
  token,
  setShopDetails,
  setErrorMessage,
  setSuccessMessage,
  setIsEditing
) => {
  try {
    const coordinates = await getCoordinatesFromAddress(shopDetails.address);
    const updatedDetails = {
      ...shopDetails,
      location: {
        address: shopDetails.address,
        coordinates: {
          lat: parseFloat(coordinates.lat),
          long: parseFloat(coordinates.long),
        },
      },
    };

    const updatedData = await updateShopDetails(shopId, updatedDetails, token);
    setShopDetails({
      ...updatedData.shop,
      location: {
        coordinates: {
          lat: updatedData.shop.location?.coordinates?.lat || '',
          long: updatedData.shop.location?.coordinates?.long || '',
        },
        address: updatedData.shop.address || '',
      },
    });
    setSuccessMessage('Shop details updated successfully.');
    setIsEditing(false);
  } catch (err) {
    setErrorMessage('Failed to update shop details.');
  }
};

// Fetch shop details by username
export const handleFetchShopDetails = async (shopUsername, setShopDetails, setError) => {
  try {
    const data = await getShopByUsername(shopUsername); // Corrected function name
    setShopDetails(data);
    setError('');
  } catch (error) {
    console.error('Failed to fetch shop details:', error);
    setError('Failed to fetch shop details.');
  }
};

// Handle logout
export const handleLogout = (setIsLoggedIn, setToken, setShopUsername, setShopId, navigate) => {
  setIsLoggedIn(false);
  setToken('');
  setShopUsername('');
  setShopId('');
  localStorage.clear();
  navigate('/login');
};

// Fetch the shop name based on login status
export const fetchShopName = async (isLoggedIn, shopUsername, token, setShopName, setError) => {
  try {
    if (isLoggedIn && token) {
      const shopId = localStorage.getItem('shopId');
      const shop = await getShopById(shopId, token);
      setShopName(shop.name);
    } else if (shopUsername) {
      const shop = await getShopByUsername(shopUsername);
      setShopName(shop.name);
    } else {
      setShopName('Tattoo Scheduling');
    }
  } catch (error) {
    setError('Error fetching shop details.');
  }
};
