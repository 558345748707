import React, { useEffect, useState, useContext } from 'react';
import { useParams, Outlet, useNavigate } from 'react-router-dom'; // Use useNavigate to redirect
import axiosInstance from '../services/axiosInstance';
import { ShopContext } from '../context/ShopContext';

const ShopWrapper = () => {
  const { shopUsername } = useParams(); // Capture shopUsername from URL params
  const [shop, setShop] = useState(null);
  const [error, setError] = useState(false);
  const token = localStorage.getItem('token'); // Get the token from localStorage
  const { setShopId, setShopUsername } = useContext(ShopContext);
  const navigate = useNavigate(); // Navigate for redirection

  useEffect(() => {
    // Check if token or shoptokenUsername is missing
    if (!shopUsername) {
      console.error('Invalid shopUsername.');
      setError(true);

      // Optionally redirect to a login page or home
      navigate('/'); // Redirect to login if token is missing
      return;
    }
  
    const fetchShop = async () => {
      try {
        const res = await axiosInstance.get(`/shop/username/${shopUsername}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!res.data || !res.data._id) {
          // Prevent setting a null or invalid shop
          console.error('Shop data is invalid or null.');
          setError(true);
          return;
        }

        setShop(res.data);

        // Set the shop userName and ID in the context only if valid
        setShopId(res.data._id);
        setShopUsername(res.data.username);

        console.log('Shop details from ShopWrapper:', res.data);

      } catch (error) {
        console.error('Failed to fetch shop details:', error);
        setError(true);
      }
    };
  
    fetchShop();
  }, [shopUsername, token, setShopId, setShopUsername, navigate]);

  // Error handling for missing token or invalid shop
  if (error) {
    return <p>Shop not found or there was an error fetching shop details. Please check your connection and try again.</p>;
  }

  // Show loading state
  if (!shop) {
    return <p>Loading shop details...</p>;
  }

  // Render nested routes
  return (
    <div>
      <Outlet /> {/* Render the nested routes */}
    </div>
  );
};

export default ShopWrapper;
