import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode'; // Corrected import

// Create the context
export const ShopContext = createContext();

// Provider component
export const ShopProvider = ({ children }) => {
  const [shopUsername, setShopUsername] = useState(localStorage.getItem('shopUsername') || '');
  const [shopId, setShopId] = useState(localStorage.getItem('shopId') || '');
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  // Function to decode and verify the token's expiration
  const isTokenValid = (token) => {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      return decoded.exp > currentTime; // Check if token is still valid
    } catch (error) {
      return false; // Token is invalid
    }
  };

  // Logout function to clear state and localStorage
  const logout = () => {
    setShopUsername('');
    setShopId('');
    setToken('');
    setIsLoggedIn(false);
    localStorage.removeItem('shopUsername');
    localStorage.removeItem('shopId');
    localStorage.removeItem('token');
  };

  // Handle shopUsername changes in localStorage
  useEffect(() => {
    if (shopUsername) {
      localStorage.setItem('shopUsername', shopUsername);
    } else {
      localStorage.removeItem('shopUsername');
    }
  }, [shopUsername]);

  // Handle shopId changes in localStorage
  useEffect(() => {
    if (shopId) {
      localStorage.setItem('shopId', shopId);
    } else {
      localStorage.removeItem('shopId');
    }
  }, [shopId]);

  // Handle token changes and validate token
  useEffect(() => {
    if (token && isTokenValid(token)) {
      localStorage.setItem('token', token);
      setIsLoggedIn(true);
    } else {
      logout(); // Log the user out and clear state if token is invalid or missing
    }
  }, [token]);

  return (
    <ShopContext.Provider
      value={{
        shopUsername,
        setShopUsername,
        shopId,
        setShopId,
        token,
        setToken,
        isLoggedIn,
        setIsLoggedIn,
        logout, // Expose the logout function
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};
