import axiosInstance from '../services/axiosInstance';

export const getUnavailableDates = async (shopId, artistName, token) => {
  const response = await axiosInstance.get(`/shop/${shopId}/unavailable-dates/${artistName}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const addUnavailableDate = async (shopId, unavailableDate, token) => {
  const response = await axiosInstance.post(`/shop/${shopId}/unavailable-dates`, unavailableDate, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const deleteUnavailableDate = async (shopId, dateId, token) => {
  return await axiosInstance.delete(`/shop/${shopId}/unavailable-dates/${dateId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
