// src/views/TattooStyleDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchTattooStyleById } from '../controllers/StyleController';

const TattooStyleDetails = () => {
  const { styleId } = useParams(); // Get styleId from the URL
  const [style, setStyle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // Use useNavigate for navigation

  // Fetch tattoo style details on component mount
  useEffect(() => {
    fetchTattooStyleById(styleId, setStyle, setLoading, setErrorMessage);
  }, [styleId]);

  // Utility function to format description text
  const formatDescription = (description) => {
    return description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  // Handle back navigation
  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  if (loading) {
    return <p className="text-center">Loading style details...</p>;
  }

  if (errorMessage) {
    return <p className="text-red-500 text-center">{errorMessage}</p>;
  }

  if (!style) {
    return <p className="text-center">Style not found.</p>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <button
        onClick={handleBackClick}
        className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 mb-4"
      >
        Back
      </button>
      <h2 className="text-3xl font-bold mb-4 text-center">{style.name}</h2>
      {style.image && (
        <img
          src={`${style.image}`} // Display style image
          alt={style.name}
          className="w-full h-64 object-cover mb-4 rounded"
        />
      )}
      <p className="text-lg text-gray-600">{formatDescription(style.description)}</p>
    </div>
  );
};

export default TattooStyleDetails;
