import {
  getArtists,
  addArtist,
  deleteArtist,
  getArtistById,
  updateArtist,
  addService,
  removeService,
  addStyle,
  removeStyle,
  fetchStyles,
  getUnavailableDatesForArtist,
  addUnavailableDate,
  deleteUnavailableDate,
} from '../models/ArtistModel';
import { getAvailableStyles } from '../models/StyleModel';
import { fetchReviewsForArtist } from '../models/ReviewModel';

// Fetch all artists
export const fetchArtists = async (shopId, token, setArtists, setSelectedArtist, setError) => {
  try {
    const data = await getArtists(shopId, token);
    setArtists(data);
    setSelectedArtist(data[0] || null); // Select the first artist by default
  } catch (error) {
    setError('Failed to load artists.');
  }
};

// Handle fetching artists
export const handleFetchArtists = async (shopId, setArtists, setLoading, setError) => {
  setLoading(true);
  try {
    const data = await getArtists(shopId);
    setArtists(data);
    setError('');
  } catch (error) {
    setError('Failed to load artists.');
    console.error('Error fetching artists:', error);
  } finally {
    setLoading(false);
  }
};

// Add a new artist
export const handleAddArtist = async (shopId, token, newArtist, setArtists, setIsSubmitting, setError) => {
  setIsSubmitting(true);
  try {
    const addedArtist = await addArtist(shopId, token, newArtist);
    setArtists((prevArtists) => [...prevArtists, addedArtist]);
  } catch (error) {
    setError('Artist failed to add');
  } finally {
    setIsSubmitting(false);
  }
};

// Delete an artist
export const handleDeleteArtist = async (
  shopId,
  artistId,
  token,
  setArtists,
  setSelectedArtist,
  setError,
  setIsModalOpen // Add setIsModalOpen here to control modal state
) => {
  try {
    await deleteArtist(shopId, artistId, token); // Ensure the delete action is awaited
    setArtists((prevArtists) => prevArtists.filter((artist) => artist._id !== artistId));
    setSelectedArtist(null); // Clear the selected artist after deletion
    setIsModalOpen(false); // Close the modal after successful deletion
  } catch (error) {
    setError('Failed to delete artist, or artist has already been deleted.');
  }
};


// Fetch artist data along with available styles
export const fetchArtistData = async (shopId, artistId, setArtist, setAvailableStyles, setLoading, setError) => {
  setLoading(true);
  try {
    const artistData = await getArtistById(shopId, artistId);
    const availableStylesData = await getAvailableStyles();

    // Map artist styles (IDs) to their corresponding names
    const mappedStyles = artistData.styles.map((styleId) => {
      const style = availableStylesData.find((s) => s._id === styleId);
      return style ? { _id: style._id, name: style.name } : { _id: styleId, name: 'Unknown Style' };
    });

    setAvailableStyles(availableStylesData); // Store available styles
    setArtist({
      ...artistData,
      styles: mappedStyles, // Replace style IDs with objects containing names and IDs
    });
  } catch (error) {
    setError('Failed to load artist data');
  } finally {
    setLoading(false);
  }
};

// Fetch styles and reviews for an artist
export const handleFetchStylesAndReviews = async (artistId, setStyles, setAvailableStyles, setReviews, setErrorMessage) => {
  try {
    // Fetch available styles
    const availableStyles = await fetchStyles();
    setAvailableStyles(availableStyles);

    // Fetch reviews or handle 404 error (no reviews found)
    const reviews = await fetchReviewsForArtist(artistId).catch((reviewError) => {
      if (reviewError.response && reviewError.response.status === 404) {
        return []; // No reviews found, return empty array
      }
      throw reviewError; // Re-throw other errors
    });
    setReviews(reviews);

    // Map artist styles (IDs) to their corresponding names
    setStyles((prevStyles) =>
      prevStyles.map((styleId) => {
        const style = availableStyles.find((s) => s._id === styleId);
        return style ? style.name : styleId;
      })
    );
  } catch (error) {
    // Error handling
    setErrorMessage('Failed to load data.');
    console.error('Error fetching styles and reviews:', error.message || error);
  }
};


// Update artist details
export const handleUpdateArtist = async (shopId, artistId, formData, token, setArtist, setErrorMessage, setIsEditing) => {
  try {
    const updatedArtist = await updateArtist(shopId, artistId, formData, token);
    setArtist(updatedArtist);
    setIsEditing(false);
  } catch (error) {
    setErrorMessage('Failed to update artist.');
  }
};

// Add a service to an artist
export const handleAddService = async (shopId, artistId, serviceInput, token, setServices, setErrorMessage) => {
  if (!serviceInput) {
    setErrorMessage('Service name is required.');
    return;
  }
  try {
    const updatedServices = await addService(shopId, artistId, serviceInput, token);
    setServices(updatedServices);
  } catch (error) {
    setErrorMessage('Failed to add service.');
  }
};

// Remove a service from an artist
export const handleRemoveService = async (shopId, artistId, service, token, setServices, setErrorMessage) => {
  try {
    await removeService(shopId, artistId, service, token);
    setServices((prevServices) => prevServices.filter((s) => s !== service));
  } catch (error) {
    setErrorMessage('Failed to remove service.');
  }
};

// Add a style to an artist
export const handleAddStyle = async (shopId, artistId, styleInput, styles, availableStyles, token, setStyles, setErrorMessage) => {
  // Check if the style already exists
  if (!styleInput || styles.some((style) => style === styleInput)) {
    setErrorMessage('Please select a valid style or the style already exists.');
    return;
  }
  try {
    const updatedStyles = await addStyle(shopId, artistId, styleInput, token);
    const updatedStylesWithNames = updatedStyles.map((styleId) => {
      const style = availableStyles.find((s) => s._id === styleId);
      return style ? style.name : styleId;
    });
    setStyles(updatedStylesWithNames);
  } catch (error) {
    setErrorMessage('Failed to add style.');
  }
};

// Remove a style from an artist
export const handleRemoveStyle = async (shopId, artistId, styleName, availableStyles, token, setStyles, setErrorMessage) => {
  const styleToRemove = availableStyles.find((style) => style.name === styleName);

  if (!styleToRemove) {
    setErrorMessage('Style not found.');
    return;
  }
  try {
    const updatedStyles = await removeStyle(shopId, artistId, styleToRemove._id, token);
    const updatedStylesWithNames = updatedStyles.map((styleId) => {
      const style = availableStyles.find((s) => s._id === styleId);
      return style ? style.name : styleId;
    });
    setStyles(updatedStylesWithNames);
    setErrorMessage(''); // Clear error message on success
  } catch (error) {
    setErrorMessage('Failed to remove style.');
  }
};

// Fetch unavailable dates for the selected artist
export const fetchUnavailableDates = async (shopId, artistName, token, setUnavailableDates, setError) => {
  try {
    const unavailableDates = await getUnavailableDatesForArtist(shopId, artistName, token);
    setUnavailableDates(unavailableDates);
    setError('');
  } catch (error) {
    setError('Failed to load unavailable dates.');
    console.error('Error fetching unavailable dates:', error);
  }
};

// Add a new unavailable date
export const handleAddUnavailableDate = async (
  shopId,
  selectedArtist,
  newUnavailableDate,
  newStartTime,
  newEndTime,
  recurrencePattern,
  recurrenceEndDate,
  token,
  setNewUnavailableDate,
  setNewStartTime,
  setNewEndTime,
  setRecurrencePattern,
  setRecurrenceEndDate,
  fetchUnavailableDates,
  setError
) => {
  try {
    const parsedDate = new Date(`${newUnavailableDate}T00:00:00`);
    const unavailableDateData = {
      artistName: selectedArtist.name,
      date: parsedDate.toISOString(),
      startTime: newStartTime,
      endTime: newEndTime,
      recurrencePattern,
      recurrenceEndDate: recurrenceEndDate ? new Date(`${recurrenceEndDate}T00:00:00`).toISOString() : null,
    };

    await addUnavailableDate(shopId, unavailableDateData, token);

    // Reset form inputs
    setNewUnavailableDate('');
    setNewStartTime('');
    setNewEndTime('');
    setRecurrencePattern('');
    setRecurrenceEndDate('');
    fetchUnavailableDates();
    setError('');
  } catch (error) {
    setError('Failed to add unavailable date.');
    console.error('Error adding unavailable date:', error);
  }
};

// Delete an unavailable date
export const handleDeleteUnavailableDate = async (id, shopId, token, fetchUnavailableDates, setError) => {
  try {
    await deleteUnavailableDate(shopId, id, token);
    fetchUnavailableDates(); // Refresh after deletion
    setError('');
  } catch (error) {
    setError('Failed to delete unavailable date.');
    console.error('Error deleting unavailable date:', error);
  }
};