import React from 'react';

const TattooProcess = () => {
  const steps = [
    { number: 1, title: 'Booking', description: 'Schedule an appointment through our website or call us.' },
    { number: 2, title: 'Consultation', description: 'Discuss your ideas with our artists.' },
    { number: 3, title: 'Design', description: 'Our artist will create a custom design for you.' },
    { number: 4, title: 'Tattoo Session', description: 'Get your tattoo done in a comfortable environment.' },
    { number: 5, title: 'Aftercare', description: 'Receive instructions on how to care for your new tattoo.' },
  ];

  return (
    <div className="my-10 max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold text-center text-indigo-600 mb-6">Tattoo Process Overview</h2>
      <ol className="list-decimal list-inside space-y-4">
        {steps.map((step) => (
          <li key={step.number} className="bg-gray-100 p-4 rounded-lg shadow hover:shadow-lg transition">
            <h3 className="text-xl font-semibold text-indigo-600">{step.title}</h3>
            <p className="text-gray-700">{step.description}</p>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default TattooProcess;
