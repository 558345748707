// controllers/GalleryController.js
import { fetchArtistGallery, uploadGalleryImages, deleteGalleryImage } from '../models/GalleryModel';

export const handleFetchGallery = async (shopId, artistId, setGallery, setError) => {
  try {
    const data = await fetchArtistGallery(shopId, artistId);
    if (data) {
      setGallery(data);
      setError('');
    } else {
      setError('No gallery data found.');
    }
  } catch (error) {
    setError('Error fetching gallery');
  }
};

export const handleUploadImages = async (shopId, artistId, selectedFiles, token, setGallery, setError, setSelectedFiles) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('galleryImages', selectedFiles[i]);
    }

    await uploadGalleryImages(shopId, artistId, formData, token);
    const updatedGallery = await fetchArtistGallery(shopId, artistId); // Fetch the updated gallery
    setGallery(updatedGallery);
    setSelectedFiles(null); // Clear selected files after upload
    setError(''); // Clear any errors after successful upload
  } catch (error) {
    setError('Failed to upload images.');
  }
};

export const handleDeleteImage = async (shopId, artistId, imageFilename, token, gallery, setGallery, setError) => {
  try {
    await deleteGalleryImage(shopId, artistId, imageFilename, token);
    setGallery(gallery.filter((image) => image !== imageFilename)); // Update the gallery state
    setError(''); // Clear any errors after successful deletion
  } catch (error) {
    setError('Failed to delete image.');
  }
};
