// DeleteReview.js
import React, { useState } from 'react';
import { handleDeleteReview } from '../controllers/ReviewController';

const DeleteReview = ({ artistId, reviewId, onDelete }) => {
  const [error, setError] = useState(''); // State for error handling

  const handleDelete = () => {
    handleDeleteReview(artistId, reviewId, onDelete, setError);
  };

  return (
    <>
      {error && <p className="text-red-500">{error}</p>}
      <button
        onClick={handleDelete}
        className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition"
      >
        Delete Review
      </button>
    </>
  );
};

export default DeleteReview;
