// src/views/Management.js
import React, { useState, useEffect } from 'react';
import { handleDeleteAppointment, fetchAppointmentsAndArtists } from '../controllers/ManagementController';
import AppointmentsTable from '../components/AppointmentsTable';
import Pagination from '../components/Pagination';
import Message from '../components/Message';
import ConfirmationModal from '../components/ConfirmationModal';
import AppointmentsCalendarView from '../components/AppointmentsCalendarView';
import AppointmentStatistics from '../components/AppointmentStatistics';
import { jwtDecode } from 'jwt-decode';

function Management({ token }) {
  const [appointments, setAppointments] = useState([]);
  const [artists, setArtists] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  const [pastAppointments, setPastAppointments] = useState([]);
  const [futureAppointments, setFutureAppointments] = useState([]);
  const [isShowingPastAppointments, setIsShowingPastAppointments] = useState(false);
  const [isShowingCalendar, setIsShowingCalendar] = useState(false);
  const [currentPageFuture, setCurrentPageFuture] = useState(1);
  const [currentPagePast, setCurrentPagePast] = useState(1);
  const appointmentsPerPage = 10;
  const [shopId, setShopId] = useState(null);

  // Extract shopId from token
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const shopIdFromToken = decodedToken.shopId || decodedToken.user?.shopId;
        if (shopIdFromToken) {
          setShopId(shopIdFromToken);
        } else {
          setError('shopId not found in token');
          setLoading(false);
        }
      } catch (e) {
        setError('Invalid token');
        setLoading(false);
      }
    } else {
      setError('No token provided');
      setLoading(false);
    }
  }, [token]);

  // Fetch appointments and artists
  useEffect(() => {
    if (token && shopId) {
      fetchAppointmentsAndArtists(
        shopId,
        token,
        setAppointments,
        setArtists,
        setPastAppointments,
        setFutureAppointments,
        setLoading,
        setError
      );
    } else {
      setLoading(false);
    }
  }, [token, shopId]);
 // Handle artist selection change
 const handleArtistChange = (e) => {
  const selectedArtistId = e.target.value;
  setSelectedArtist(selectedArtistId);

  if (selectedArtistId) {
    const filteredPast = appointments.filter(
      (appointment) =>
        appointment.artistId &&
        appointment.artistId._id === selectedArtistId &&
        new Date(appointment.startTime) < new Date()
    );
    const filteredFuture = appointments.filter(
      (appointment) =>
        appointment.artistId &&
        appointment.artistId._id === selectedArtistId &&
        new Date(appointment.startTime) >= new Date()
    );
    setPastAppointments(filteredPast);
    setFutureAppointments(filteredFuture);
  } else {
    const currentDate = new Date();
    setPastAppointments(appointments.filter(
      (appointment) => new Date(appointment.startTime) < currentDate
    ));
    setFutureAppointments(appointments.filter(
      (appointment) => new Date(appointment.startTime) >= currentDate
    ));
  }
};
  // Open delete confirmation modal
  const openDeleteModal = (id) => {
    setAppointmentToDelete(id);
    setIsModalOpen(true);
  };

  // Close delete confirmation modal
  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setAppointmentToDelete(null);
  };

  return (
    <div className="container mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-center mb-6">Management Portal</h2>
      {error && <Message message={error} type="error" />}
      {success && <Message message={success} type="success" />}

      {loading ? (
        <div className="text-center">
          <p>Loading data...</p>
        </div>
      ) : (
        <>
          <div className="flex justify-between mb-6">
            <select
              className="border border-gray-300 rounded-lg py-2 px-4"
              value={selectedArtist}
              onChange={handleArtistChange}
            >
              <option value="">All Artists</option>
              {artists.map((artist) => (
                <option key={artist._id} value={artist._id}>
                  {artist.name}
                </option>
              ))}
            </select>
            <button
              className="bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-600 transition"
              onClick={() => setIsShowingCalendar(!isShowingCalendar)}
            >
              {isShowingCalendar ? 'Show List View' : 'Show Calendar View'}
            </button>
          </div>

          {isShowingCalendar ? (
            <AppointmentsCalendarView appointments={appointments} artists={artists} />
          ) : (
            <>
              {isShowingPastAppointments ? (
                <div className="mb-6">
                  <h3 className="text-xl font-semibold mb-4">Past Appointments</h3>
                  <AppointmentsTable
                    appointments={pastAppointments.slice(
                      currentPagePast * appointmentsPerPage - appointmentsPerPage,
                      currentPagePast * appointmentsPerPage
                    )}
                    artists={artists}
                    onDelete={openDeleteModal}
                  />
                  {pastAppointments.length > appointmentsPerPage && (
                    <Pagination
                      totalPages={Math.ceil(pastAppointments.length / appointmentsPerPage)}
                      currentPage={currentPagePast}
                      onPageChange={setCurrentPagePast}
                    />
                  )}
                </div>
              ) : (
                <div className="mb-6">
                  <h3 className="text-xl font-semibold mb-4">Upcoming Appointments</h3>
                  <AppointmentsTable
                    appointments={futureAppointments.slice(
                      currentPageFuture * appointmentsPerPage - appointmentsPerPage,
                      currentPageFuture * appointmentsPerPage
                    )}
                    artists={artists}
                    onDelete={openDeleteModal}
                  />
                  {futureAppointments.length > appointmentsPerPage && (
                    <Pagination
                      totalPages={Math.ceil(futureAppointments.length / appointmentsPerPage)}
                      currentPage={currentPageFuture}
                      onPageChange={setCurrentPageFuture}
                    />
                  )}
                </div>
              )}
            </>
          )}

          <div className="flex justify-end">
            {!isShowingCalendar && (
              <button
                className="bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-600 transition"
                onClick={() => setIsShowingPastAppointments(!isShowingPastAppointments)}
              >
                {isShowingPastAppointments ? 'Show Upcoming Appointments' : 'Show Past Appointments'}
              </button>
            )}
          </div>

          {/* Appointment Statistics Component */}
          <AppointmentStatistics
            appointments={appointments}
            pastAppointments={pastAppointments}
            futureAppointments={futureAppointments}
            artists={artists}
          />

          <ConfirmationModal
            isOpen={isModalOpen}
            onConfirm={() =>
              handleDeleteAppointment(
                shopId,
                token,
                appointmentToDelete,
                appointments,
                setAppointments,
                setPastAppointments,
                setFutureAppointments,
                setSuccess,
                (error) => setError(error), // Fix: Ensure setError is correctly passed
                setIsModalOpen
              )
            }
            onCancel={closeDeleteModal}
          />
        </>
      )}
    </div>
  );
}

export default Management;
