// ReviewList.js
import React, { useEffect, useState } from 'react';
import { handleFetchReviews } from '../controllers/ReviewController';

function ReviewList({ artistId }) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    handleFetchReviews(artistId, setReviews, setLoading, setError);
  }, [artistId]);

  const renderStars = (rating) => {
    const totalStars = 5;
    const filledStars = Math.min(rating, totalStars); // Ensure no more than 5 filled stars
    const emptyStars = totalStars - filledStars;

    return (
      <div className="text-yellow-500">
        {'★'.repeat(filledStars)}
        <span className="text-gray-300">{'★'.repeat(emptyStars)}</span>
      </div>
    );
  };

  if (loading) {
    return <p className="text-gray-500 text-center">Loading reviews...</p>;
  }

  if (error) {
    return <p className="text-red-500 text-center">{error}</p>;
  }

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-2xl font-bold mb-6 text-center">Customer Reviews</h3>

      {reviews.length > 0 ? (
        <div className="space-y-6">
          {reviews.map((review) => (
            <div
              key={review._id}
              className="bg-gray-50 p-5 rounded-lg shadow hover:shadow-lg transition"
            >
              {/* Reviewer Title */}
              <div classname="flex items-center">
                <p className="text-lg font-bold">{review.title}</p>
              </div>
              {/* Star Rating */}
              <div className="flex items-center mb-2">
                {renderStars(review.rating)}
                <p className="ml-2 text-sm text-gray-700">{review.rating} / 5</p>
              </div>

              {/* Review Comment */}
              <p className="mt-2 text-gray-700">{review.comment}</p>

              {/* Reviewer Name and Date */}
              <p className="mt-4 text-sm text-gray-500">
                <strong>{review.reviewerName}</strong> on{' '}
                {new Date(review.createdAt).toLocaleDateString()}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600 text-center">No reviews yet.</p>
      )}
    </div>
  );
}

export default ReviewList;
