import React, { useState, useEffect } from 'react';
import DeleteReview from './DeleteReview';
import {
  handleFetchStylesAndReviews,
  handleUpdateArtist,
  handleAddService,
  handleRemoveService,
  handleAddStyle,
  handleRemoveStyle,
} from '../controllers/ArtistController';
import { formatPhoneNumber } from '../utils/utils';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the reusable spinner

const ArtistCard = ({ artist, token, onArtistUpdate }) => {
  const [bioInput, setBioInput] = useState(artist.bio || '');
  const [instagramInput, setInstagramInput] = useState(artist.socialMedia?.instagram || '');
  const [phoneNumberInput, setPhoneNumberInput] = useState(artist.phoneNumber || '');
  const [imageInput, setImageInput] = useState(null);
  const [serviceInput, setServiceInput] = useState('');
  const [services, setServices] = useState(artist.services || []);
  const [styleInput, setStyleInput] = useState('');
  const [styles, setStyles] = useState(artist.styles || []);
  const [availableStyles, setAvailableStyles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); // Error message
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // New loading state for image upload
  const [reviews, setReviews] = useState([]); // Reviews for the artist

  const shopId = localStorage.getItem('shopId');

  // Fetch styles and reviews on component load
  useEffect(() => {
    if (artist) {
      setBioInput(artist.bio || '');
      setInstagramInput(artist.socialMedia?.instagram || '');
      setPhoneNumberInput(artist.phoneNumber || '');
      setServices(artist.services || []);
      setStyles(artist.styles || []);
      setImageInput(artist.image || null);

      // Fetch styles and reviews when the artist changes
      handleFetchStylesAndReviews(artist._id, setStyles, setAvailableStyles, setReviews, setErrorMessage);
    }
  }, [artist]);

  // Toggle between view and edit mode
  const toggleEdit = () => setIsEditing(!isEditing);

  // Handle the artist update
  const handleUpdate = async () => {
    const formData = new FormData();
    formData.append('bio', bioInput);
    formData.append('instagram', instagramInput);
    formData.append('phoneNumber', phoneNumberInput); // Include formatted phone number
    formData.append('services', JSON.stringify(services));
    formData.append('styles', JSON.stringify(styles));
    if (imageInput) formData.append('image', imageInput);

    setIsUploading(true); // Start the loading state

    try {
      await handleUpdateArtist(shopId, artist._id, formData, token, onArtistUpdate, setErrorMessage, setIsEditing);
    } finally {
      setIsUploading(false); // End the loading state
    }
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhoneNumberInput(formattedPhone); // Format the phone number as the user types
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-6">
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

      <h3 className="text-xl font-semibold mb-4">{artist.name}</h3>
      {artist.image && (
        <img
          src={artist.image}  // artist.image should contain the full S3 URL
          alt={artist.name}  // Optional: Alt tag for accessibility
          className="w-40 h-40 object-cover rounded-full mb-4 mx-auto"  // Ensure these classes are correct for your design
        />
      )}

      {/* Edit Mode */}
      {!isEditing ? (
        <div className="mb-6">
          <p className="whitespace-pre-line">{bioInput}</p>
          {instagramInput && (
            <p className="mt-4">
              <strong>Instagram: </strong>
              <a
                href={`https://instagram.com/${instagramInput}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                @{instagramInput}
              </a>
            </p>
          )}
          {phoneNumberInput && (
            <p className="mt-4">
              <strong>Phone Number: </strong>{phoneNumberInput}
            </p>
          )}
          <button
            className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 transition"
            onClick={toggleEdit}
          >
            Edit
          </button>
        </div>
      ) : (
        <div className="mb-6">
          <textarea
            value={bioInput}
            onChange={(e) => setBioInput(e.target.value)}
            placeholder="Edit Bio"
            className="w-full p-2 border border-gray-300 rounded mb-4"
          />
          <input
            type="text"
            value={instagramInput}
            onChange={(e) => setInstagramInput(e.target.value)}
            placeholder="Instagram Handle"
            className="w-full p-2 border border-gray-300 rounded mb-4"
          />
          <input
            type="text"
            value={phoneNumberInput}
            onChange={handlePhoneNumberChange} // Format phone number as user types
            placeholder="Phone Number"
            className="w-full p-2 border border-gray-300 rounded mb-4"
          />
          <input
            type="file"
            onChange={(e) => setImageInput(e.target.files[0])}
            className="mb-4"
          />
          <button
            className={`bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition mr-2 ${
              isUploading ? 'bg-gray-500 cursor-not-allowed' : ''
            }`}
            onClick={handleUpdate}
            disabled={isUploading} // Disable the button while uploading
          >
            {isUploading ? <LoadingSpinner /> : 'Save'}
          </button>
          <button
            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition"
            onClick={toggleEdit}
          >
            Cancel
          </button>
        </div>
      )}

      {/* Services Section */}
      <div>
        <h4 className="text-lg font-semibold mb-2">Services</h4>
        <ul className="list-disc pl-6 mb-4">
        {services.length > 0 ? (
            services.map((service, index) => (
              <li key={index} className="flex justify-between items-center mb-2">
                <span>{service}</span>
                <button
                  className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600 transition"
                  onClick={() => handleRemoveService(shopId, artist._id, service, token, setServices, setErrorMessage)}
                >
                  Remove
                </button>
              </li>
            ))
          ) : (
            <p className="text-gray-500">No services available.</p> // Display a message when the list is empty
          )}

        </ul>

        <div className="flex items-center mb-4">
          <input
            type="text"
            value={serviceInput}
            onChange={(e) => setServiceInput(e.target.value)}
            placeholder="Add new service"
            className="w-full p-2 border border-gray-300 rounded"
          />
          <button
            className="ml-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
            onClick={() => handleAddService(shopId, artist._id, serviceInput, token, setServices, setErrorMessage)}
          >
            Add Service
          </button>
        </div>
      </div>

      {/* Styles Section */}
      <div>
        <h4 className="text-lg font-semibold mb-2">Styles</h4>
        <ul className="list-disc pl-6 mb-4">
          {styles.map((style, index) => (
            <li key={index} className="flex justify-between items-center mb-2">
              <span>{style}</span>
              <button
                className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600 transition"
                onClick={() => handleRemoveStyle(shopId, artist._id, style, availableStyles, token, setStyles, setErrorMessage)}
                >
                Remove
              </button>
            </li>
          ))}
        </ul>

        <div className="flex items-center mb-4">
          <select
            value={styleInput}
            onChange={(e) => setStyleInput(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="" disabled>Select a style</option>
            {availableStyles.map((style) => (
              <option key={style._id} value={style.name}>
                {style.name}
              </option>
            ))}
          </select>
          <button
            className="ml-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
            onClick={() => handleAddStyle(shopId, artist._id, styleInput, styles, availableStyles, token, setStyles, setErrorMessage)}
          >
            Add Style
          </button>
        </div>
      </div>

      {/* Reviews Section */}
      <div className="bg-gray-50 p-4 rounded-lg shadow-md">
        <h4 className="text-xl font-semibold mb-4 text-gray-800">Customer Reviews</h4>
        <ul className="space-y-4">
          {reviews.length > 0 ? (
            reviews.map((review) => (
              <li key={review._id} className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                <p className="text-gray-700 mb-2">"{review.comment}"</p>
                <p className="text-sm text-gray-500 mb-4">Rating: <span className="text-yellow-500">{review.rating} / 5</span></p>
                <div className="flex justify-end">
                  <DeleteReview
                    artistId={artist._id}
                    reviewId={review._id}
                    onDelete={(deletedReviewId) =>
                      setReviews((prevReviews) => prevReviews.filter((review) => review._id !== deletedReviewId))
                    }
                  />
                </div>
              </li>
            ))
          ) : (
            <p className="text-gray-500">No reviews yet.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ArtistCard;
