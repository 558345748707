import axiosInstance from '../services/axiosInstance';

// Fetch appointment summary for a shop
export const getAppointmentsSummary = async (shopId) => {
  const response = await axiosInstance.get(`/shop/${shopId}/appointments/summary`);
  return response.data;
};

// Fetch unavailable dates for a specific artist in a shop
export const getUnavailableDates = async (shopId, artist) => {
  const response = await axiosInstance.get(`/shop/${shopId}/unavailable-dates/${artist}`);
  return response.data;
};

// Create a new appointment
export const createAppointment = async (shopId, appointmentData) => {
  const response = await axiosInstance.post(`/shop/${shopId}/appointments`, appointmentData);
  return response.data;
};

// Fetch appointments for a shop
export const getAppointmentsForShop = async (shopId, token) => {
  const response = await axiosInstance.get(`/shop/${shopId}/appointments`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const deleteAppointment = async (shopId, token, appointmentId) => {
    await axiosInstance.delete(`/shop/${shopId}/appointments/${appointmentId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
  