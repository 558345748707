// ShopSettings.js
import React, { useState, useEffect } from 'react';
import { handleFetchShopDetailsById, handleSubmitShopDetails } from '../controllers/ShopController';
import { jwtDecode } from 'jwt-decode';

function ShopSettings({ token }) {
  const [shopDetails, setShopDetails] = useState({
    name: '',
    address: '',
    phoneNumber: '',
    email: '',
    openingHours: '',
    bio: '',
    location: { coordinates: { lat: '', long: '' }, address: '' },
  });
  const [shopId, setShopId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Extract shopId from token
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const shopIdFromToken = decodedToken.shopId || decodedToken.user?.shopId;
        if (shopIdFromToken) {
          setShopId(shopIdFromToken);
        } else {
          setErrorMessage('shopId not found in token');
        }
      } catch (e) {
        setErrorMessage('Invalid token');
      }
    } else {
      setErrorMessage('No token provided');
    }
  }, [token]);

  // Fetch shop details when shopId becomes available
  useEffect(() => {
    if (shopId) {
      handleFetchShopDetailsById(shopId, token, setShopDetails, setErrorMessage);
    }
  }, [token, shopId]);

  // Handle form input changes
  const handleChange = (e) => {
    setShopDetails({ ...shopDetails, [e.target.name]: e.target.value });
    setErrorMessage('');
    setSuccessMessage('');
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitShopDetails(shopId, shopDetails, token, setShopDetails, setErrorMessage, setSuccessMessage, setIsEditing);
  };

  return (
    <div className="max-w-4xl mx-auto bg-white p-6 shadow-md rounded-lg">
      <h3 className="text-xl font-semibold mb-4">Shop Details</h3>
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

      {!isEditing ? (
        <div className="space-y-4">
          <p><strong>Name:</strong> {shopDetails.name}</p>
          <p><strong>Address:</strong> {shopDetails.address}</p>
          <p><strong>Phone Number:</strong> {shopDetails.phoneNumber}</p>
          <p><strong>Email:</strong> {shopDetails.email}</p>
          <p><strong>Opening Hours:</strong> {shopDetails.openingHours}</p>
          <p><strong>Bio:</strong> {shopDetails.bio}</p>
          <p><strong>Latitude:</strong> {shopDetails.location.coordinates.lat}</p>
          <p><strong>Longitude:</strong> {shopDetails.location.coordinates.long}</p>
          <button
            onClick={() => setIsEditing(true)}
            className="bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition"
          >
            Edit Shop Details
          </button>
        </div>
      ) : (
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label className="block text-sm font-semibold mb-1">Name:</label>
            <input
              type="text"
              name="name"
              value={shopDetails.name}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold mb-1">Address:</label>
            <textarea
              name="address"
              value={shopDetails.address}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold mb-1">Phone Number:</label>
            <input
              type="text"
              name="phoneNumber"
              value={shopDetails.phoneNumber}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold mb-1">Email:</label>
            <input
              type="email"
              name="email"
              value={shopDetails.email}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold mb-1">Opening Hours:</label>
            <textarea
              name="openingHours"
              value={shopDetails.openingHours}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold mb-1">Bio:</label>
            <textarea
              name="bio"
              value={shopDetails.bio}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div className="flex justify-between items-center">
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default ShopSettings;
