import React, { useEffect, useState } from 'react';
import moment from 'moment'; // For date formatting
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Required for using Chart.js

function AppointmentStatistics({ appointments, pastAppointments, futureAppointments, artists }) {
  const [currentPage, setCurrentPage] = useState(1);
  const appointmentsPerPage = 30; // Show 30 days per page

  useEffect(() => {
    console.log('Artists passed to AppointmentStatistics:', artists);
    console.log('Appointments passed to AppointmentStatistics:', appointments);
  }, [artists, appointments]);

  const totalAppointments = appointments.length;
  const totalPastAppointments = pastAppointments.length;
  const totalFutureAppointments = futureAppointments.length;

  // Create a color palette
  const colorPalette = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#C9CBCF'];

  // Create a lookup table to map each artist to a color
  const artistColorMap = artists.reduce((acc, artist, index) => {
    acc[artist._id] = colorPalette[index % colorPalette.length]; // Assign a color from the palette
    return acc;
  }, {});

  // Helper function to get the full range of dates between two dates
  const getDateRange = (startDate, endDate) => {
    const dateArray = [];
    let currentDate = moment(startDate);
    const stopDate = moment(endDate);
    while (currentDate <= stopDate) {
      dateArray.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'days');
    }
    return dateArray;
  };

  // Get the earliest and latest date from the appointments
  const allDates = appointments.map((appointment) => moment(appointment.startTime).format('YYYY-MM-DD'));
  const minDate = moment.min(allDates.map((date) => moment(date))).format('YYYY-MM-DD');
  const maxDate = moment.max(allDates.map((date) => moment(date))).format('YYYY-MM-DD');
  const fullDateRange = getDateRange(minDate, maxDate); // Full date range from earliest to latest appointment

  // Function to group appointments by day and artist
  const groupByArtistAndDay = (appointmentsList) => {
    return artists.reduce((artistAcc, artist) => {
      const artistId = artist._id;
      const artistAppointments = appointmentsList.filter(
        (appointment) => appointment.artistId && appointment.artistId._id === artistId
      );

      const dayGroup = artistAppointments.reduce((dayAcc, appointment) => {
        const day = moment(appointment.startTime).format('YYYY-MM-DD');
        if (!dayAcc[day]) {
          dayAcc[day] = 1;
        } else {
          dayAcc[day]++;
        }
        return dayAcc;
      }, {});

      artistAcc[artist.name] = dayGroup;
      return artistAcc;
    }, {});
  };

  // Group past and future appointments by artist and day
  const pastAppointmentsByArtistAndDay = groupByArtistAndDay(pastAppointments);
  const futureAppointmentsByArtistAndDay = groupByArtistAndDay(futureAppointments);

  // Calculate appointments per artist for summary statistics
  const artistStatistics = artists.reduce((acc, artist) => {
    const artistId = artist._id;

    const total = appointments.filter(
      (appointment) => appointment.artistId && appointment.artistId._id === artistId
    ).length;

    const past = pastAppointments.filter(
      (appointment) => appointment.artistId && appointment.artistId._id === artistId
    ).length;

    const future = futureAppointments.filter(
      (appointment) => appointment.artistId && appointment.artistId._id === artistId
    ).length;

    acc[artist.name] = {
      totalAppointments: total,
      pastAppointments: past,
      futureAppointments: future,
    };

    return acc;
  }, {});

  // Generate chart data for appointments by day for each artist
  const generateChartData = () => {
    const startIndex = (currentPage - 1) * appointmentsPerPage;
    const currentDates = fullDateRange.slice(startIndex, startIndex + appointmentsPerPage);

    const datasets = artists.map((artist) => {
      const artistPastData = pastAppointmentsByArtistAndDay[artist.name] || {};
      const artistFutureData = futureAppointmentsByArtistAndDay[artist.name] || {};
      
      const data = currentDates.map((date) => {
        return (artistPastData[date] || 0) + (artistFutureData[date] || 0);
      });

      return {
        label: artist.name,
        data,
        fill: false,
        borderColor: artistColorMap[artist._id], // Use the artist's assigned color
        tension: 0.1,
      };
    });

    return {
      labels: currentDates,
      datasets,
    };
  };

  const chartData = generateChartData();

  // Pagination controls for the chart
  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const totalPages = Math.ceil(fullDateRange.length / appointmentsPerPage);
  const disableNextPage = currentPage >= totalPages;

  return (
    <div className="appointment-statistics max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6 mb-8">
      <h3 className="text-2xl font-semibold text-center mb-6">Appointment Statistics</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-gray-100 p-4 rounded-lg text-center">
          <h4 className="text-lg font-medium">Total Appointments</h4>
          <p className="text-xl font-semibold">{totalAppointments}</p>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg text-center">
          <h4 className="text-lg font-medium">Past Appointments</h4>
          <p className="text-xl font-semibold">{totalPastAppointments}</p>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg text-center">
          <h4 className="text-lg font-medium">Upcoming Appointments</h4>
          <p className="text-xl font-semibold">{totalFutureAppointments}</p>
        </div>
      </div>

      <h4 className="text-lg font-semibold mb-4">Appointments Over Time (Graph):</h4>
      <div className="mb-6">
        <Line data={chartData} />
        <div className="flex justify-between items-center mt-4">
          <button 
            onClick={handlePreviousPage} 
            disabled={currentPage === 1}
            className={`bg-gray-200 text-gray-600 py-2 px-4 rounded-lg hover:bg-gray-300 transition ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            Previous
          </button>
          <span className="text-gray-700">
            Page {currentPage} of {totalPages}
          </span>
          <button 
            onClick={handleNextPage} 
            disabled={disableNextPage}
            className={`bg-gray-200 text-gray-600 py-2 px-4 rounded-lg hover:bg-gray-300 transition ${disableNextPage ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            Next
          </button>
        </div>
      </div>

      <h4 className="text-lg font-semibold mb-4">Appointments by Artist:</h4>
      <ul className="mb-6">
        {Object.entries(artistStatistics).map(([artistName, stats]) => (
          <li key={artistName} className="mb-2">
            <strong>{artistName}</strong>
            <ul className="list-disc ml-6">
              <li>Total Appointments: {stats.totalAppointments}</li>
              <li>Past Appointments: {stats.pastAppointments}</li>
              <li>Upcoming Appointments: {stats.futureAppointments}</li>
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AppointmentStatistics;
