// controllers/AppointmentController.js
import {
    getAppointmentsSummary,
    getUnavailableDates,
    createAppointment,
  } from '../models/AppointmentModel';
  import { getArtists } from '../models/ArtistModel';

  export const fetchData = async (shopId, setArtists, setAppointments, setError) => {
    try {
      const [artistsData, appointmentsData] = await Promise.all([
        getArtists(shopId),
        getAppointmentsSummary(shopId),
      ]);
      setArtists(artistsData);
      setAppointments(appointmentsData);
    } catch (error) {
      setError('Failed to load data');
    }
  };
  
  export const handleArtistChange = (e, setArtistName, setFilteredAppointments, setUnavailableDates, setServices, appointments, artists, fetchUnavailableDates) => {
    const artist = e.target.value;
    setArtistName(artist);
  
    // Clear previous filtered appointments and unavailable dates
    setFilteredAppointments([]);
    setUnavailableDates([]);
  
    // Filter appointments for the selected artist
    const filtered = appointments.filter(appointment => appointment.artistId.name === artist);
    setFilteredAppointments(filtered);
  
    // Fetch services and unavailable dates for the selected artist
    const selectedArtist = artists.find(a => a.name === artist);
    setServices(selectedArtist ? selectedArtist.services : []);
    fetchUnavailableDates(artist);
  };
  
  export const fetchUnavailableDatesForArtist = async (shopId, artist, setUnavailableDates, setError) => {
    try {
      const unavailableDates = await getUnavailableDates(shopId, artist);
      setUnavailableDates(unavailableDates);
    } catch (error) {
      setError('Failed to load unavailable dates');
    }
  };
  
  export const handleSubmitAppointment = async (e, shopId, appointmentData, setError, setSuccess, resetForm, refreshData) => {
    e.preventDefault();
  
    try {
      await createAppointment(shopId, appointmentData);
      setSuccess('Appointment scheduled successfully!');
      setError('');
      resetForm();  // Reset the form after successful submission
      refreshData(); // Fetch updated data after scheduling the appointment
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to schedule appointment.');
      setSuccess('');
    }
  };

// Check if a day is booked for a specific artist
export const isBookedDayForArtist = (date, filteredAppointments) => {
    return filteredAppointments.some(appointment => {
      const appointmentDate = new Date(appointment.startTime);
  
      const yearMatch = appointmentDate.getFullYear() === date.getFullYear();
      const monthMatch = appointmentDate.getMonth() === date.getMonth();
      const dayMatch = appointmentDate.getDate() === date.getDate();
  
      return yearMatch && monthMatch && dayMatch;
    });
  };
  
  // Check if a day is unavailable for the selected artist
  export const isUnavailable = (date, unavailableDates) => {
    return unavailableDates.some(unavailable => {
      const unavailableDate = new Date(unavailable.date);
      return (
        unavailableDate.getFullYear() === date.getFullYear() &&
        unavailableDate.getMonth() === date.getMonth() &&
        unavailableDate.getDate() === date.getDate()
      );
    });
  };
  
export const resetForm = (
    setTitle,
    setDescription,
    setPhoneNumber,
    setSelectedTime,
    setEndTime,
    setArtistName,
    setSelectedService,
    setSelectedDate,
    setFirstName,
    setLastName,
    setFilteredAppointments,
    setUnavailableDates
  ) => {
    setTitle('');
    setDescription('');
    setPhoneNumber('');
    setSelectedTime('');
    setEndTime('');
    setArtistName('');
    setSelectedService('');
    setSelectedDate(new Date());
    setFirstName('');
    setLastName('');
    setFilteredAppointments([]);
    setUnavailableDates([]);
  };
  
  export const refreshData = async (shopId, setArtists, setAppointments, setError) => {
    try {
      await fetchData(shopId, setArtists, setAppointments, setError);
    } catch (error) {
      setError('Failed to refresh data.');
    }
  };
  // controllers/AppointmentController.js

