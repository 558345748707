// src/controllers/AppController.js
import { loadSavedShopData } from '../models/ShopModel';

export const initializeShopContext = (setToken, setShopUsername, setShopId, setIsLoggedIn) => {
  const { token, shopUsername, shopId } = loadSavedShopData();

  if (token && shopUsername && shopId) {
    setToken(token);
    setShopUsername(shopUsername);
    setShopId(shopId);
    setIsLoggedIn(true);
  } else {
    setIsLoggedIn(false);
  }
};
