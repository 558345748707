import { getAppointmentsForShop, deleteAppointment } from '../models/AppointmentModel';
import { getArtists } from '../models/ArtistModel';

// Function to fetch appointments and artists
export const fetchAppointmentsAndArtists = async (
  shopId, 
  token, 
  setAppointments, 
  setArtists, 
  setPastAppointments, 
  setFutureAppointments, 
  setLoading, 
  setError
) => {
  try {
    setLoading(true);

    // Fetch both appointments and artists from the models
    const [appointments, artists] = await Promise.all([
      getAppointmentsForShop(shopId, token),
      getArtists(shopId, token),
    ]);

    const sortedAppointments = appointments.sort(
      (a, b) => new Date(a.startTime) - new Date(b.startTime)
    );
    const currentDate = new Date();
    const pastAppointments = sortedAppointments.filter(
      (appointment) => new Date(appointment.startTime) < currentDate
    );
    const futureAppointments = sortedAppointments.filter(
      (appointment) => new Date(appointment.startTime) >= currentDate
    );

    setAppointments(sortedAppointments);
    setArtists(artists);
    setPastAppointments(pastAppointments);
    setFutureAppointments(futureAppointments);
    setLoading(false);
  } catch (err) {
    setError('Failed to load data');
    setLoading(false);
  }
};

// Function to delete an appointment
export const handleDeleteAppointment = async (
  shopId, 
  token, 
  appointmentToDelete, 
  appointments, 
  setAppointments, 
  setPastAppointments, 
  setFutureAppointments, 
  setSuccess, 
  setError, 
  setIsModalOpen
) => {
  try {
    // Call model function to delete the appointment
    await deleteAppointment(shopId, token, appointmentToDelete);

    const updatedAppointments = appointments.filter(
      (appointment) => appointment._id !== appointmentToDelete
    );

    const currentDate = new Date();
    const pastAppointments = updatedAppointments.filter(
      (appointment) => new Date(appointment.startTime) < currentDate
    );
    const futureAppointments = updatedAppointments.filter(
      (appointment) => new Date(appointment.startTime) >= currentDate
    );

    setAppointments(updatedAppointments);
    setPastAppointments(pastAppointments);
    setFutureAppointments(futureAppointments);
    setSuccess('Appointment deleted successfully');
    setIsModalOpen(false);
  } catch (err) {
    setError('Failed to delete appointment');
  }
};
