// AppointmentCalendar.js
import React, { useState, useEffect, useContext } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { ShopContext } from '../context/ShopContext';
import '../styles/AppointmentCalendar.css';
import {formatPhoneNumber} from '../utils/utils';

import {
  fetchData,
  handleArtistChange,
  fetchUnavailableDatesForArtist,
  handleSubmitAppointment,
  isBookedDayForArtist,
  isUnavailable,
  resetForm, // Import resetForm
  refreshData, // Import refreshData
} from '../controllers/AppointmentController';

function AppointmentCalendar() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [artistName, setArtistName] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [artists, setArtists] = useState([]);
  const [services, setServices] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [filteredAppointments, setFilteredAppointments] = useState([]);

  const { shopId } = useContext(ShopContext);

  // Fetch artists and appointment summary
  useEffect(() => {
    if (shopId) {
      fetchData(shopId, setArtists, setAppointments, setError);
    }
  }, [shopId]);

  return (
    <div className="max-w-5xl mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6 text-center">Schedule an Appointment</h2>

      {/* Artist Selection */}
      <div className="mb-6">
        <label className="block mb-2 text-lg font-semibold">Select an Artist</label>
        <select
          value={artistName}
          onChange={(e) =>
            handleArtistChange(
              e,
              setArtistName,
              setFilteredAppointments,
              setUnavailableDates,
              setServices,
              appointments,
              artists,
              (artist) => fetchUnavailableDatesForArtist(shopId, artist, setUnavailableDates, setError)
            )
          }
          className="w-full p-2 border border-gray-300 rounded-lg"
          required
        >
          <option value="">Select an Artist</option>
          {artists.map((artist) => (
            <option key={artist._id} value={artist.name}>
              {artist.name}
            </option>
          ))}
        </select>
      </div>

      {/* Calendar Component */}
      <div className="mb-6">
        <label className="block mb-2 text-lg font-semibold">Select a Date</label>
        <Calendar
          onChange={setSelectedDate}
          value={selectedDate}
          minDate={new Date()}
          tileClassName={({ date, view }) => {
            if (
              view === 'month' &&
              (isBookedDayForArtist(date, filteredAppointments) || isUnavailable(date, unavailableDates))
            ) {
              return 'highlight-day';
            }
            return null;
          }}
          className="border border-gray-300 rounded-lg p-4"
        />
      </div>

      {/* Form for scheduling appointments */}
      <form
  onSubmit={(e) =>
    handleSubmitAppointment(
      e,
      shopId,
      {
        title,
        description,
        date: selectedDate.toISOString().split('T')[0],
        time: selectedTime,  // Start time
        endTime,  // End time
        phoneNumber,
        artistId: artists.find((artist) => artist.name === artistName)?._id,
        selectedService,
        firstName,
        lastName,
      },
      setError,
      setSuccess,
      () => resetForm(
        setTitle,
        setDescription,
        setPhoneNumber,
        setSelectedTime,
        setEndTime,
        setArtistName,
        setSelectedService,
        setSelectedDate,
        setFirstName,
        setLastName,
        setFilteredAppointments,
        setUnavailableDates
      ),
      () => refreshData(shopId, setArtists, setAppointments, setError)
    )
  }
>

  {/* Start Time and End Time */}
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
    <div>
      <label className="block mb-2 text-lg font-semibold">Start Time</label>
      <input
        type="time"
        value={selectedTime}
        onChange={(e) => setSelectedTime(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-lg"
        required
      />
    </div>

    <div>
      <label className="block mb-2 text-lg font-semibold">End Time (Optional)</label>
      <input
        type="time"
        value={endTime}
        onChange={(e) => setEndTime(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded-lg"
      />
    </div>
  </div>
  {/* First Name and Last Name */}
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
    <input
      type="text"
      placeholder="First Name"
      value={firstName}
      onChange={(e) => setFirstName(e.target.value)}
      className="w-full p-2 border border-gray-300 rounded-lg"
      required
    />
    <input
      type="text"
      placeholder="Last Name"
      value={lastName}
      onChange={(e) => setLastName(e.target.value)}
      className="w-full p-2 border border-gray-300 rounded-lg"
      required
    />
  </div>

  {/* Appointment Title */}
  <div className="mb-6">
    <input
      type="text"
      placeholder="Appointment Title"
      value={title}
      onChange={(e) => setTitle(e.target.value)}
      className="w-full p-2 border border-gray-300 rounded-lg"
      required
    />
  </div>

  {/* Description */}
  <div className="mb-6">
    <textarea
      placeholder="Description"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      className="w-full p-2 border border-gray-300 rounded-lg"
      required
    />
  </div>

  {/* Phone Number */}
  <div className="mb-6">
    <input
      type="tel"
      placeholder="Phone Number"
      value={phoneNumber}
      onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))}
      className="w-full p-2 border border-gray-300 rounded-lg"
      required
    />
  </div>

  {/* Service Selection */}
  <div className="mb-6">
    <label className="block mb-2 text-lg font-semibold">Select a Service</label>
    <select
      value={selectedService}
      onChange={(e) => setSelectedService(e.target.value)}
      className="w-full p-2 border border-gray-300 rounded-lg"
      required
    >
      <option value="">Select a Service</option>
      {services.map((service, index) => (
        <option key={index} value={service}>
          {service}
        </option>
      ))}
    </select>
  </div>


  {/* Submit Button */}
  <button
    type="submit"
    className="w-full bg-indigo-600 text-white p-3 rounded-lg hover:bg-indigo-700 transition"
  >
    Schedule Appointment
  </button>
</form>



      {success && <p className="text-green-500 mt-6">{success}</p>}
      {error && <p className="text-red-500 mt-6">{error}</p>}
    </div>
  );
}

export default AppointmentCalendar;
