import React, { useState } from 'react';
import { formatPhoneNumber } from '../utils/utils';

function NewArtistForm({ token, onArtistAdd, shopId, isSubmitting }) {
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [instagram, setInstagram] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); // New phone number state
  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || isSubmitting) {
      console.log('Submission blocked: either name is missing or form is submitting.');
      return;
    }

    const newArtist = { name, bio, instagram, phoneNumber, image };
    onArtistAdd(newArtist);
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhone); // Format the phone number as the user types
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md space-y-4">
      <h3 className="text-xl font-semibold mb-4">Add New Artist</h3>

      <div className="space-y-2">
        <label htmlFor="artist-name" className="block text-sm font-medium text-gray-700">
          Artist Name
        </label>
        <input
          id="artist-name"
          type="text"
          placeholder="Artist Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          disabled={isSubmitting}
          className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="artist-bio" className="block text-sm font-medium text-gray-700">
          Artist Bio
        </label>
        <textarea
          id="artist-bio"
          placeholder="Artist Bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          disabled={isSubmitting}
          className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="artist-instagram" className="block text-sm font-medium text-gray-700">
          Instagram Handle
        </label>
        <input
          id="artist-instagram"
          type="text"
          placeholder="Instagram Handle"
          value={instagram}
          onChange={(e) => setInstagram(e.target.value)}
          disabled={isSubmitting}
          className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>

      {/* New phone number input */}
      <div className="space-y-2">
        <label htmlFor="artist-phone" className="block text-sm font-medium text-gray-700">
          Phone Number
        </label>
        <input
          id="artist-phone"
          type="text"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={handlePhoneNumberChange} // Apply formatting here
          disabled={isSubmitting}
          className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="artist-image" className="block text-sm font-medium text-gray-700">
          Artist Image
        </label>
        <input
          id="artist-image"
          type="file"
          onChange={(e) => setImage(e.target.files[0])}
          disabled={isSubmitting}
          className="block w-full text-gray-700 py-2 px-4 border border-gray-300 rounded-md"
        />
      </div>

      <button
        type="submit"
        disabled={isSubmitting}
        className={`w-full py-2 px-4 text-white rounded-lg ${isSubmitting ? 'bg-gray-500' : 'bg-indigo-600 hover:bg-indigo-700 transition'} `}
      >
        {isSubmitting ? 'Adding...' : 'Add Artist'}
      </button>

      {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
    </form>
  );
}

export default NewArtistForm;
