// src/views/Header.js
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ShopContext } from '../context/ShopContext';
import { fetchShopName } from '../controllers/ShopController'; // Import the controller

function Header({ isLoggedIn, token }) {
  const [isOpen, setIsOpen] = useState(false);
  const { shopUsername } = useContext(ShopContext);
  const [shopName, setShopName] = useState('Tattoo Scheduling'); // Default name
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch the shop name based on login status
    fetchShopName(isLoggedIn, shopUsername, token, setShopName, setError);
  }, [isLoggedIn, shopUsername, token]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-gray-900 text-white shadow-md sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center p-4 relative">
        {/* Logo */}
        
        <h1 className="text-2xl font-bold">
          <Link to={`/${shopUsername}`} className="hover:text-gray-400">
            {shopName}
          </Link>
        </h1>

        {/* Hamburger Icon (Mobile) */}
        <button
          className="lg:hidden text-white focus:outline-none absolute right-4 top-1/2 transform -translate-y-1/2"
          onClick={toggleMenu}
        >
          &#9776;
        </button>

        {/* Navigation */}
        <nav className={`${isOpen ? 'block' : 'hidden'} w-full lg:flex lg:items-center lg:w-auto`}>
          <ul className="flex flex-col lg:flex-row lg:space-x-6 text-center lg:text-left">
            <li>
              <Link
                to="/"
                className="block py-2 lg:py-0 hover:text-gray-400"
                onClick={() => setIsOpen(false)} // Close menu on link click
              >
                Home
              </Link>
            </li>
            {shopUsername && (
              <li>
                <Link
                  to={`/${shopUsername}/book`}
                  className="block py-2 lg:py-0 hover:text-gray-400"
                  onClick={() => setIsOpen(false)} // Close menu on link click
                >
                  Book Appointment
                </Link>
              </li>
            )}
            {isLoggedIn && (
              <>
                <li>
                  <Link
                    to={`/${shopUsername}/management`}
                    className="block py-2 lg:py-0 hover:text-gray-400"
                    onClick={() => setIsOpen(false)} // Close menu on link click
                  >
                    Management Portal
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${shopUsername}/settings`}
                    className="block py-2 lg:py-0 hover:text-gray-400"
                    onClick={() => setIsOpen(false)} // Close menu on link click
                  >
                    Settings
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
      {error && <p className="text-red-500 text-center">{error}</p>}
    </header>
  );
}

export default Header;
