import React, { useState } from 'react';
import Calendar from 'react-calendar'; // Import react-calendar
import Pagination from './Pagination'; // Import Pagination component
import 'react-calendar/dist/Calendar.css'; // Import calendar styles
import '../styles/UnavailableDates.css'; // Import custom styles

function UnavailableDates({
  unavailableDates,
  isEditing,
  handleDeleteUnavailableDate,
  newUnavailableDate,
  setNewUnavailableDate,
  newStartTime,
  setNewStartTime,
  newEndTime,
  setNewEndTime,
  handleAddUnavailableDate,
  recurrencePattern,
  setRecurrencePattern,
  recurrenceEndDate,
  setRecurrenceEndDate,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const datesPerPage = 5; // Adjust the number of items per page as needed

  // Calculate the total number of pages
  const totalPages = Math.ceil(unavailableDates.length / datesPerPage);

  // Calculate the current page's items
  const startIndex = (currentPage - 1) * datesPerPage;
  const currentDates = unavailableDates.slice(startIndex, startIndex + datesPerPage);

  // Convert unavailableDates to a Date array for the calendar
  const unavailableDateList = unavailableDates.map(date => new Date(date.date));

  // Handle date selection (for editing)
  const handleDateClick = (date) => {
    if (isEditing) {
      setNewUnavailableDate(date.toISOString().split('T')[0]); // Set the selected date
    }
  };

  // Check if a date is unavailable
  const isUnavailable = (date) => {
    return unavailableDateList.some((unavailableDate) => {
      return (
        unavailableDate.getFullYear() === date.getFullYear() &&
        unavailableDate.getMonth() === date.getMonth() &&
        unavailableDate.getDate() === date.getDate()
      );
    });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Unavailable Dates</h2>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {/* Calendar to display unavailable dates */}
        <div className="calendar-wrapper bg-gray-100 p-4 rounded-lg shadow">
          <Calendar
            onClickDay={handleDateClick}
            tileClassName={({ date, view }) =>
              view === 'month' && isUnavailable(date) ? 'highlight-day' : null
            }
            className="rounded-lg"
          />
        </div>

        {/* Add new unavailable date form */}
        {isEditing && (
          <div className="add-unavailable-date bg-gray-100 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-4">Add New Unavailable Date</h3>
            <input
              type="date"
              value={newUnavailableDate}
              onChange={(e) => setNewUnavailableDate(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
              required
            />
            <input
              type="time"
              value={newStartTime}
              onChange={(e) => setNewStartTime(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
              required
            />
            <input
              type="time"
              value={newEndTime}
              onChange={(e) => setNewEndTime(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
              required
            />
            <select
              value={recurrencePattern}
              onChange={(e) => setRecurrencePattern(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            >
              <option value="">No Recurrence</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
            <input
              type="date"
              value={recurrenceEndDate}
              onChange={(e) => setRecurrenceEndDate(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
              placeholder="Recurrence End Date"
            />
            <button
              onClick={handleAddUnavailableDate}
              className="w-full bg-indigo-600 text-white p-2 rounded-lg hover:bg-indigo-700 transition"
            >
              + Add Unavailable Date
            </button>
          </div>
        )}
      </div>

      {/* List of unavailable dates with pagination */}
      <ul className="space-y-4 mb-6">
        {currentDates.length > 0 ? (
          currentDates.map((date, index) => {
            const formattedDate = date?.date ? new Date(date.date).toLocaleDateString() : 'Invalid date';
            const formattedStartTime = date?.startTime || 'Invalid start time';
            const formattedEndTime = date?.endTime || 'Invalid end time';

            return (
              <li key={index} className="flex justify-between items-center bg-gray-50 p-4 rounded-lg shadow">
                <span>
                  Unavailable on {formattedDate} from {formattedStartTime} to {formattedEndTime}
                </span>
                {isEditing && (
                  <button
                    className="text-red-500 hover:text-red-700 transition"
                    onClick={() => handleDeleteUnavailableDate(date._id)}
                  >
                    ✖
                  </button>
                )}
              </li>
            );
          })
        ) : (
          <p className="text-center">No unavailable dates listed</p>
        )}
      </ul>

      {/* Pagination */}
      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
}

export default UnavailableDates;
