import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axiosInstance from '../services/axiosInstance';

const TattooStyleGuide = () => {
  const [styles, setStyles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchStyles = async () => {
      try {
        const res = await axiosInstance.get('/styles');
        setStyles(res.data);
      } catch (error) {
        setErrorMessage('Failed to load tattoo styles.');
      }
    };

    fetchStyles();
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="my-10">
      <h2 className="text-2xl font-bold text-center mb-4 animate-fadeIn">Tattoo Style Guide</h2>
      {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}

      {styles.length >= 3 ? (
        <Slider {...sliderSettings}>
          {styles.map((style) => (
            <a
              key={style._id}
              href={`/styles/${style._id}`}
              className="block bg-white p-4 rounded-lg shadow-md mx-2 flex flex-col justify-between h-96 animate-slideInUp transition-transform duration-300 hover:scale-105"
            >
              <img
                src={`${style.image}`}
                alt={style.name}
                className="w-full h-36 object-cover mb-4 rounded transition-transform duration-300 hover:scale-105"
              />
              <div className="flex flex-col flex-grow justify-between">
                <h3 className="text-lg font-semibold mb-1">{style.name}</h3>
                <p className="text-gray-600 line-clamp-3">{style.description}</p>
                <span className="text-indigo-600 hover:underline mt-2">Read more</span>
              </div>
            </a>
          ))}
        </Slider>
      ) : (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {styles.map((style) => (
            <a
              key={style._id}
              href={`/styles/${style._id}`}
              className="block bg-white p-4 rounded-lg shadow-md mx-2 flex flex-col justify-between h-96 animate-slideInUp transition-transform duration-300 hover:scale-105"
            >
              <img
                src={`${style.image}`}
                alt={style.name}
                className="w-full h-36 object-cover mb-4 rounded transition-transform duration-300 hover:scale-105"
              />
              <div className="flex flex-col flex-grow justify-between">
                <h3 className="text-lg font-semibold mb-1">{style.name}</h3>
                <p className="text-gray-600 line-clamp-3">{style.description}</p>
                <span className="text-indigo-600 hover:underline mt-2">Read more</span>
              </div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default TattooStyleGuide;
