// utils.js

/**
 * Formats a phone number into (XXX) XXX-XXXX format.
 * @param {string} value - The phone number string.
 * @returns {string} - The formatted phone number.
 */
export const formatPhoneNumber = (value) => {
    if (!value) return value;
    const cleaned = value.replace(/[^\d]/g, ''); // Clean the input to contain only digits
    const length = cleaned.length;
    
    if (length < 4) return cleaned;
    if (length < 7) return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
    
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
  };
  