// src/models/ShopModel.js
import axiosInstance from '../services/axiosInstance';

// Fetch the logged-in shop's name (if the user is logged in)
export const getLoggedInShopName = async (token) => {
  const response = await axiosInstance.get('/shop/profile', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.name;
};

// Fetch the selected shop's name when not logged in
export const getSelectedShopName = async (shopUsername) => {
  const response = await axiosInstance.get(`/shop/username/${shopUsername}`);
  return response.data.name;
};

// Load saved shop data from localStorage
export const loadSavedShopData = () => {
  return {
    token: localStorage.getItem('token'),
    shopUsername: localStorage.getItem('shopUsername'),
    shopId: localStorage.getItem('shopId'),
  };
};

// Fetch shop details by shop username
export const getShopByUsername = async (shopUsername) => {
  const response = await axiosInstance.get(`/shop/username/${shopUsername}`);
  return response.data;
};

// Fetch logged-in shop details by shop ID (from token)
export const getShopById = async (shopId, token) => {
  const response = await axiosInstance.get(`/shop/${shopId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Update shop details
export const updateShopDetails = async (shopId, updatedDetails, token) => {
  const response = await axiosInstance.put(`/shop/${shopId}`, updatedDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Fetch artists for the shop
export const fetchArtistsForShop = async (shopId) => {
  const response = await axiosInstance.get(`/shop/${shopId}/artists`);
  return response.data;
};

// Fetch reviews for the shop's artists
export const fetchArtistReviews = async (artistId) => {
  const response = await axiosInstance.get(`/artists/${artistId}/reviews`);
  return response.data;
};

// Fetch ranked shops
export const getRankedShops = async () => {
  const response = await axiosInstance.get('/shop/rankings');
  return response.data;
};

// Fetch coordinates from an address using Nominatim API
export const getCoordinatesFromAddress = async (address) => {
  const response = await axiosInstance.get(
    `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&limit=1`
  );
  
  if (response.data.length > 0) {
    const location = response.data[0];
    return {
      lat: location.lat,
      long: location.lon,
    };
  } else {
    throw new Error('Unable to get coordinates');
  }
};

// Shop Gallery Functions

// Fetch gallery images for a shop
export const getShopGallery = async (shopId, token) => {
  const response = await axiosInstance.get(`/shop/${shopId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.gallery || [];
};

// Upload images to the shop gallery
export const uploadShopImages = async (shopId, images, token) => {
  const formData = new FormData();
  images.forEach((image) => formData.append('galleryImages', image));

  const response = await axiosInstance.post(`/shop/${shopId}/upload-images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.shop.gallery;
};

// Delete an image from the shop gallery
export const deleteShopImage = async (shopId, imagePath, token) => {
  try {
    const response = await axiosInstance.delete(`/shop/${shopId}/delete-image`, {
      headers: { Authorization: `Bearer ${token}` },
      data: { imagePath }, // Sending imagePath in the request body
    });
    return response.data; // Return the response if needed
  } catch (error) {
    console.error('Error deleting shop image:', error.response?.data || error.message);
    throw error; // Propagate the error so it can be handled by the caller
  }
};
