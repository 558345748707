import React, { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosInstance';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the LoadingSpinner component

const ManageTattooStyles = () => {
  const [styles, setStyles] = useState([]);
  const [newStyle, setNewStyle] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newImage, setNewImage] = useState(null);
  const [editingStyle, setEditingStyle] = useState(null); // Store the style being edited
  const [editName, setEditName] = useState(''); // Name to edit
  const [editDescription, setEditDescription] = useState(''); // Description to edit
  const [editImage, setEditImage] = useState(null); // Image to edit
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false); // New loading state for image upload

  useEffect(() => {
    const fetchStyles = async () => {
      try {
        const response = await axiosInstance.get('/styles');
        setStyles(response.data);
      } catch (error) {
        setErrorMessage('Failed to load styles.');
      }
    };

    fetchStyles();
  }, []);

  // Function to replace \n with <br> tags for multi-line text

  const formatDescription = (text) => {
    return (text || '').split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const handleAddStyle = async (e) => {
    e.preventDefault();

    if (!newStyle || !newDescription || !newImage) {
      setErrorMessage('All fields are required.');
      return;
    }

    setIsUploading(true); // Start loading state

    try {
      const formData = new FormData();
      formData.append('name', newStyle);
      formData.append('description', newDescription);
      formData.append('image', newImage);

      const response = await axiosInstance.post('/styles', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setStyles([...styles, response.data]);
      setNewStyle('');
      setNewDescription('');
      setNewImage(null);
      setSuccessMessage('Style added successfully!');
    } catch (error) {
      setErrorMessage('Failed to add style.');
    } finally {
      setIsUploading(false); // End loading state
    }
  };

  const handleDeleteStyle = async (styleId) => {
    try {
      await axiosInstance.delete(`/styles/${styleId}`);
      setStyles(styles.filter((style) => style._id !== styleId));
      setSuccessMessage('Style deleted successfully!');
    } catch (error) {
      setErrorMessage('Failed to delete style.');
    }
  };

  const handleEditStyle = (style) => {
    setEditingStyle(style._id);
    setEditName(style.name);
    setEditDescription(style.description);
    setEditImage(null);
  };

  const handleUpdateStyle = async (e) => {
    e.preventDefault();
    
    if (!editName || !editDescription) {
      setErrorMessage('All fields are required.');
      return;
    }

    setIsUploading(true); // Start loading state

    try {
      const formData = new FormData();
      formData.append('name', editName);
      formData.append('description', editDescription);
      
      // Only append the image if a new one is selected
      if (editImage) {
        formData.append('image', editImage);
      }

      const response = await axiosInstance.put(`/styles/${editingStyle}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Update the styles list with the updated style
      setStyles(styles.map((style) => (style._id === editingStyle ? response.data : style)));
      setEditingStyle(null); // Exit edit mode
      setSuccessMessage('Style updated successfully!');
    } catch (error) {
      setErrorMessage('Failed to update style.');
    } finally {
      setIsUploading(false); // End loading state
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Manage Tattoo Styles</h2>
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      {successMessage && <p className="text-green-500">{successMessage}</p>}

      <form onSubmit={handleAddStyle} className="mb-6">
        <input
          type="text"
          placeholder="New Style Name"
          value={newStyle}
          onChange={(e) => setNewStyle(e.target.value)}
          className="border p-2 rounded-lg w-full mb-2"
          required
        />
        <textarea
          placeholder="Style Description"
          value={newDescription}
          onChange={(e) => setNewDescription(e.target.value)}
          className="border p-2 rounded-lg w-full mb-2"
          required
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setNewImage(e.target.files[0])}
          className="border p-2 rounded-lg w-full mb-2"
          required
        />
        <button
          type="submit"
          className={`bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 mt-2 ${
            isUploading ? 'bg-gray-500 cursor-not-allowed' : ''
          }`}
          disabled={isUploading} // Disable button during upload
        >
          {isUploading ? <LoadingSpinner /> : 'Add Style'} {/* Show spinner when uploading */}
        </button>
      </form>

      <h3 className="text-xl font-semibold mb-2">Existing Styles</h3>
      <ul className="list-disc pl-5">
        {styles.map((style) => (
          <li key={style._id} className="flex justify-between items-center mb-2">
            <div>
              <span className="font-bold">{style.name}</span>
              <p>{formatDescription(style.description)}</p> {/* Format the description */}
              {style.image && (
                <img
                  src={`${style.image}`} // Adjust this path based on your backend setup
                  alt={style.name}
                  className="w-32 h-32 object-cover mt-2 rounded-lg"
                />
              )}
            </div>
            <div>
              <button
                onClick={() => handleEditStyle(style)}
                className="text-blue-500 hover:underline mr-4"
              >
                Edit
              </button>
              <button
                onClick={() => handleDeleteStyle(style._id)}
                className="text-red-500 hover:underline"
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>

      {/* Edit Form */}
      {editingStyle && (
        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-2">Edit Style</h3>
          <form onSubmit={handleUpdateStyle} className="mb-6">
            <input
              type="text"
              placeholder="Edit Style Name"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              className="border p-2 rounded-lg w-full mb-2"
              required
            />
            <textarea
              placeholder="Edit Style Description"
              value={editDescription}
              onChange={(e) => setEditDescription(e.target.value)}
              className="border p-2 rounded-lg w-full mb-2"
              required
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setEditImage(e.target.files[0])}
              className="border p-2 rounded-lg w-full mb-2"
            />
            <button
              type="submit"
              className={`bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 mt-2 ${
                isUploading ? 'bg-gray-500 cursor-not-allowed' : ''
              }`}
              disabled={isUploading} // Disable button during upload
            >
              {isUploading ? <LoadingSpinner /> : 'Update Style'} {/* Show spinner when uploading */}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ManageTattooStyles;
