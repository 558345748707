// models/StyleModel.js
import axiosInstance from '../services/axiosInstance';

export const getAvailableStyles = async () => {
  const response = await axiosInstance.get('/styles');
  return response.data;
};

// Fetch tattoo style details by ID
export const getTattooStyleById = async (styleId) => {
  const response = await axiosInstance.get(`/styles/${styleId}`);
  return response.data;
};