// controllers/ReviewController.js
import { fetchReviewsForArtist, submitReview } from '../models/ReviewModel';
import { deleteReview } from '../models/ReviewModel';
// controllers/ReviewController.js
import { getArtists } from '../models/ArtistModel';

export const calculateAverageScore = async (shopId, setAverageScore, setTotalReviews, setLoading, setError) => {
  try {
    setLoading(true);

    // Fetch all artists for the shop
    const artists = await getArtists(shopId);

    let totalScore = 0;
    let reviewCount = 0;

    // Fetch reviews for each artist
    const reviewPromises = artists.map(async (artist) => {
      try {
        const reviews = await fetchReviewsForArtist(artist._id);
        if (Array.isArray(reviews) && reviews.length > 0) {
          reviews.forEach((review) => {
            totalScore += review.rating;
            reviewCount += 1;
          });
        } else {
          console.log(`No reviews found for artist: ${artist._id}`);
        }
      } catch (error) {
        console.error(`Error fetching reviews for artist ${artist._id}:`, error);
      }
    });

    // Wait for all review promises to resolve
    await Promise.all(reviewPromises);

    // Wait for all review requests to complete
    await Promise.all(reviewPromises);

    // Set the average score and total reviews
    if (reviewCount > 0) {
      setAverageScore(totalScore / reviewCount);
    } else {
      setAverageScore(0); // No reviews
    }
    setTotalReviews(reviewCount);
    setLoading(false);
  } catch (error) {
    setError('Error fetching artists or reviews.');
    setLoading(false);
  }
};

export const handleFetchReviews = async (artistId, setReviews, setLoading, setError) => {
  setLoading(true);
  try {
    const reviews = await fetchReviewsForArtist(artistId);
    setReviews(reviews);
    setLoading(false);
  } catch (error) {
    setError('Failed to load reviews, there may be no reviews for this artist yet.');
    setLoading(false);
  }
};

export const handleReviewSubmit = async (artistId, formData, setSuccessMessage, setError, setFormData) => {
  try {
    await submitReview(artistId, formData);
    setSuccessMessage('Review added successfully!');
    setFormData({
      reviewerName: '',
      rating: 5,
      comment: '',
      title: '',
      verified: false,
      images: [],
    });
    setError(''); // Clear error on successful submission
  } catch (err) {
    console.error('Error submitting review:', err.response?.data || err.message);
    setError('Failed to submit review. Please make sure all required fields are filled.');
    setSuccessMessage(''); // Clear success message on error
  }
};

export const handleDeleteReview = async (artistId, reviewId, onDelete, setError) => {
  if (window.confirm('Are you sure you want to delete this review?')) {
    try {
      await deleteReview(artistId, reviewId);
      onDelete(reviewId); // Remove the review from the UI
      setError(''); // Clear any previous errors
    } catch (error) {
      console.error('Error deleting review:', error);
      setError('Failed to delete the review.');
    }
  }
};
